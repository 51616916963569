.quid-react-components__src-components-BasicPanel-__BasicPanel {
  background-color: #FFF;
  border-radius: 2px
}

.quid-react-components__src-components-BasicPanel-__BasicPanel--column, .quid-react-components__src-components-BasicPanel-__BasicPanel--row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.quid-react-components__src-components-BasicPanel-__BasicPanel--column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column
}

.quid-react-components__src-components-BasicPanel-__BasicPanel--row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row
}

.quid-react-components__src-components-Breadcrumb-__Breadcrumb__arrow, .quid-react-components__src-components-Breadcrumb-__Breadcrumb__item {
  font-family: IBM Plex Sans,Lucida Grande,Tahoma,Verdana,Arial,sans-serif;
  color: var(--primary);
  font-size: 14px;
  line-height: 1.57;
  font-weight: normal
}
.quid-react-components__src-components-Breadcrumb-__Breadcrumb__item--active, .quid-react-components__src-components-Breadcrumb-__Breadcrumb__item--emphasized {
  font-weight: bold
}
.quid-react-components__src-components-Breadcrumb-__Breadcrumb__arrow, .quid-react-components__src-components-Breadcrumb-__Breadcrumb__item--disabled {
  color: var(--disabled)
}
.quid-react-components__src-components-Breadcrumb-__Breadcrumb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 1rem;
  padding: 0;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline
}
.quid-react-components__src-components-Breadcrumb-__Breadcrumb__arrow {
    padding-left: .75rem;
    padding-right: .75rem
}
.quid-react-components__src-components-Breadcrumb-__Breadcrumb__item {
    text-decoration: none
}
.quid-react-components__src-components-Breadcrumb-__Breadcrumb__item--disabled {
      cursor: default
}

.quid-react-components__src-components-Popover-__Popover {
  background-color: var(--primaryInverse);
  color: var(--primary);
  border: 1px solid var(--gray1);
  border-radius: 2px;
  -webkit-filter: drop-shadow(0 2px 2px rgba(18, 18, 18, 0.2));
          filter: drop-shadow(0 2px 2px rgba(18, 18, 18, 0.2));
  z-index: 1
}
.quid-react-components__src-components-Popover-__Popover__target {
    display: inline-block
}
.quid-react-components__src-components-Popover-__Popover__arrow {
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px;
    border-color: var(--primaryInverse)
}
.quid-react-components__src-components-Popover-__Popover__arrow--border {
      border-width: 7px;
      border-color: var(--gray1);
      left: -7px;
      top: -7px;
      z-index: -1
}
.quid-react-components__src-components-Popover-__Popover[data-placement^="bottom"] {
    margin-top: 7px
}
.quid-react-components__src-components-Popover-__Popover[data-placement^="bottom"] .quid-react-components__src-components-Popover-__Popover__arrow {
    top: -6px;
    border-top-width: 0;
    border-top-color: transparent;
    border-right-color: transparent;
    border-left-color: transparent
}
.quid-react-components__src-components-Popover-__Popover[data-placement^="bottom"] .quid-react-components__src-components-Popover-__Popover__arrow--border {
      top: -1px
}
.quid-react-components__src-components-Popover-__Popover[data-placement^="top"] {
    margin-bottom: 7px
}
.quid-react-components__src-components-Popover-__Popover[data-placement^="top"] .quid-react-components__src-components-Popover-__Popover__arrow {
    bottom: -6px;
    border-bottom-width: 0;
    border-bottom-color: transparent;
    border-right-color: transparent;
    border-left-color: transparent
}
.quid-react-components__src-components-Popover-__Popover[data-placement^="top"] .quid-react-components__src-components-Popover-__Popover__arrow--border {
      bottom: -1px
}
.quid-react-components__src-components-Popover-__Popover[data-placement^="left"] {
    margin-right: 7px
}
.quid-react-components__src-components-Popover-__Popover[data-placement^="left"] .quid-react-components__src-components-Popover-__Popover__arrow {
    right: -6px;
    border-right-width: 0;
    border-top-color: transparent;
    border-bottom-color: transparent;
    border-right-color: transparent
}
.quid-react-components__src-components-Popover-__Popover[data-placement^="left"] .quid-react-components__src-components-Popover-__Popover__arrow--border {
      right: -1px
}
.quid-react-components__src-components-Popover-__Popover[data-placement^="right"] {
    margin-left: 7px
}
.quid-react-components__src-components-Popover-__Popover[data-placement^="right"] .quid-react-components__src-components-Popover-__Popover__arrow {
    left: -6px;
    border-left-width: 0;
    border-top-color: transparent;
    border-left-color: transparent;
    border-bottom-color: transparent
}
.quid-react-components__src-components-Popover-__Popover[data-placement^="right"] .quid-react-components__src-components-Popover-__Popover__arrow--border {
      left: -1px
}

.quid-react-components__src-components-Tooltip-__Tooltip {
  padding: 0.35em
}

/* All sizes are based on 13px base size */

.quid-react-components__src-components-Button-__Button {
  --primaryDefaultBg: rgba(30, 215, 209, 1);
  --primaryHoverBg: rgb(27, 194, 188);
  --primaryActiveBg: rgb(24, 172, 167);
  --secondaryDefaultBg: rgba(199, 204, 209, 1);
  --secondaryHoverBg: rgba(227, 230, 232, 1);
  --secondaryActiveBg: rgb(159, 163, 167);

  /* Reset */
  border: 0;
  padding: 0;
  background: none;
  cursor: pointer;
  font-size: inherit;
  font-family: inherit;
  text-decoration: none;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  color: inherit;

  /* Style */
  display: inline-block;
  border-radius: 0.14em; /* 2px */
  line-height: 2.3; /* 30px */
  padding-left: 0.71em;
  padding-right: 0.71em;
  -webkit-transition: padding .2s ease-in-out, background .2s ease-in-out;
  -o-transition: padding .2s ease-in-out, background .2s ease-in-out;
  transition: padding .2s ease-in-out, background .2s ease-in-out;
  font-weight: bold;
  color: rgba(18, 18, 18, 1)
}

.quid-react-components__src-components-Button-__Button:focus {
    outline: 0
}

.quid-react-components__src-components-Button-__Button--primary {
    background-color: rgba(30, 215, 209, 1);
    background-color: var(--primaryDefaultBg)
}

.quid-react-components__src-components-Button-__Button--primary:hover {
      background-color: rgb(27, 194, 188);
      background-color: var(--primaryHoverBg)
}

.quid-react-components__src-components-Button-__Button--primary:active {
      background-color: rgb(24, 172, 167);
      background-color: var(--primaryActiveBg)
}

[data-whatinput="keyboard"] .quid-react-components__src-components-Button-__Button--primary:focus {
      -webkit-box-shadow: 0 0 0 0.5px white, 0 0 2px 2px rgba(30, 215, 209, 1);
              box-shadow: 0 0 0 0.5px white, 0 0 2px 2px rgba(30, 215, 209, 1)
}

.quid-react-components__src-components-Button-__Button--secondary, .quid-react-components__src-components-Button-__Button--okay, .quid-react-components__src-components-Button-__Button--warning, .quid-react-components__src-components-Button-__Button--hazard {
    background-color: rgba(199, 204, 209, 1);
    background-color: var(--secondaryDefaultBg)
}

.quid-react-components__src-components-Button-__Button--secondary:hover, .quid-react-components__src-components-Button-__Button--okay:hover, .quid-react-components__src-components-Button-__Button--warning:hover, .quid-react-components__src-components-Button-__Button--hazard:hover {
      background-color: rgba(227, 230, 232, 1);
      background-color: var(--secondaryHoverBg)
}

.quid-react-components__src-components-Button-__Button--secondary:active, .quid-react-components__src-components-Button-__Button--okay:active, .quid-react-components__src-components-Button-__Button--warning:active, .quid-react-components__src-components-Button-__Button--hazard:active {
      background-color: rgb(159, 163, 167);
      background-color: var(--secondaryActiveBg)
}

[data-whatinput="keyboard"] .quid-react-components__src-components-Button-__Button--secondary:focus, [data-whatinput="keyboard"] .quid-react-components__src-components-Button-__Button--okay:focus, [data-whatinput="keyboard"] .quid-react-components__src-components-Button-__Button--warning:focus, [data-whatinput="keyboard"] .quid-react-components__src-components-Button-__Button--hazard:focus {
      -webkit-box-shadow: 0 0 0 0.5px white, 0 0 2px 2px rgba(199, 204, 209, 1);
              box-shadow: 0 0 0 0.5px white, 0 0 2px 2px rgba(199, 204, 209, 1)
}

.quid-react-components__src-components-Button-__Button--transparent {
    background-color: transparent;
    color: rgba(18, 18, 18, 1);
    color: var(--primary)
}

.quid-react-components__src-components-Button-__Button--transparent:hover {
      background-color: rgba(127, 127, 127, .4)
}

.quid-react-components__src-components-Button-__Button--transparent:active {
      background-color: rgba(127, 127, 127, .1)
}

[data-whatinput="keyboard"] .quid-react-components__src-components-Button-__Button--transparent:focus {
      -webkit-box-shadow: 0 0 0 0.5px white, 0 0 2px 2px rgba(199, 204, 209, 1);
              box-shadow: 0 0 0 0.5px white, 0 0 2px 2px rgba(199, 204, 209, 1)
}

.quid-react-components__src-components-Button-__Button--okay {
    color: rgba(255, 255, 255, 1);
    color: var(--white);
    background-color: #039849
}

.quid-react-components__src-components-Button-__Button--okay:hover {
      background-color: rgb(3, 137, 66)
}

.quid-react-components__src-components-Button-__Button--okay:active {
      background-color: rgb(2, 122, 58)
}

[data-whatinput="keyboard"] .quid-react-components__src-components-Button-__Button--okay:focus {
      -webkit-box-shadow: 0 0 0 0.5px white, 0 0 2px 2px #039849;
              box-shadow: 0 0 0 0.5px white, 0 0 2px 2px #039849
}

.quid-react-components__src-components-Button-__Button--warning {
    color: rgba(255, 255, 255, 1);
    color: var(--white);
    background-color: #FFCE03
}

.quid-react-components__src-components-Button-__Button--warning:hover {
      background-color: rgb(230, 185, 3)
}

.quid-react-components__src-components-Button-__Button--warning:active {
      background-color: rgb(204, 165, 2)
}

[data-whatinput="keyboard"] .quid-react-components__src-components-Button-__Button--warning:focus {
      -webkit-box-shadow: 0 0 0 0.5px white, 0 0 2px 2px #FFCE03;
              box-shadow: 0 0 0 0.5px white, 0 0 2px 2px #FFCE03
}

.quid-react-components__src-components-Button-__Button--hazard {
    color: rgba(255, 255, 255, 1);
    color: var(--white);
    background-color: #E61E27
}

.quid-react-components__src-components-Button-__Button--hazard:hover {
      background-color: rgb(207, 27, 35)
}

.quid-react-components__src-components-Button-__Button--hazard:active {
      background-color: rgb(184, 24, 31)
}

[data-whatinput="keyboard"] .quid-react-components__src-components-Button-__Button--hazard:focus {
      -webkit-box-shadow: 0 0 0 0.5px white, 0 0 2px 2px #E61E27;
              box-shadow: 0 0 0 0.5px white, 0 0 2px 2px #E61E27
}

.quid-react-components__src-components-Button-__Button--small {
    line-height: 1.9
}

.quid-react-components__src-components-Button-__Button--disabled {
    cursor: default
}

.quid-react-components__src-components-Button-__Button--disabled, .quid-react-components__src-components-Button-__Button--disabled:hover, .quid-react-components__src-components-Button-__Button--disabled:focus, .quid-react-components__src-components-Button-__Button--disabled:active {
      opacity: 0.4
}

.quid-react-components__src-components-Button-__Button--disabled.quid-react-components__src-components-Button-__Button--transparent {
    background-color: transparent
}

/* alignment properties here */

.quid-react-components__src-components-Button-__Button__inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    text-align: center;
    font-weight: bold;
    -webkit-box-sizing: border-box;
            box-sizing: border-box
}

/* we must wrap our content inside a `div` element and apply the flexbox */

.quid-react-components__src-components-Button-__Button__icon {}

/* 30x30 */

.quid-react-components__src-components-Button-__Button__icon--alone {
      font-size: 1.43em;
      margin-left: calc(-0.36em / 1.538);
      margin-right: calc(-0.36em / 1.538);
      margin-top: 0.23em;
      margin-bottom: 0.23em
}

/* 24x24 */

.quid-react-components__src-components-Button-__Button__icon--small {
      font-size: 1.14em;
      margin-left: calc(-0.43em / 1.231);
      margin-right: calc(-0.43em / 1.231);
      margin-top: 0.275em;
      margin-bottom: 0.275em
}

.quid-react-components__src-components-Button-__Button__icon--side {
      margin-right: 0.36em
}

/* Due to flexbox incompatibility with `button` elements in Firefox */

.quid-react-components__src-components-Button-__Button--groupChild {}

.quid-react-components__src-components-Button-__Button--groupChild:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0
}

.quid-react-components__src-components-Button-__Button--groupChild:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0
}

.quid-react-components__src-components-Button-__Button--groupChild:not(:first-child):not(:last-child) {
      border-radius: 0
}

.quid-react-components__src-components-ButtonGroup-__ButtonGroup {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex
}
.quid-react-components__src-components-ButtonGroup-__Button {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  min-width: auto;
  border-radius: 0;
  border: 1px solid var(--gray2);
  color: var(--gray4);
  white-space: nowrap
}
.quid-react-components__src-components-ButtonGroup-__Button--inactive {
    color: rgba(18, 18, 18, 1);
    background-color: var(--white)
}
.quid-react-components__src-components-ButtonGroup-__Button--inactive:disabled {
      color: var(--gray4)
}
.quid-react-components__src-components-ButtonGroup-__Button--inactive:disabled:hover {
        background-color: var(--white)
}
.quid-react-components__src-components-ButtonGroup-__Button--active {
    color: rgba(18, 18, 18, 1);
    border: 1px solid rgba(144, 155, 163, 0.3)
}
.quid-react-components__src-components-ButtonGroup-__Button--active:disabled:hover {
      background-color: var(--primaryDefaultBg)
}
.quid-react-components__src-components-ButtonGroup-__Button--active.quid-react-components__src-components-ButtonGroup-__Button--secondary {
    border-color: rgba(144, 155, 163, 1)
}
.quid-react-components__src-components-ButtonGroup-__Button--first {
    border-radius: 2px 0 0 2px
}
.quid-react-components__src-components-ButtonGroup-__Button--last {
    border-radius: 0 2px 2px 0
}

.quid-react-components__src-components-InputText-__InputText, .quid-react-components__src-components-InputText-__InputText__input, .quid-react-components__src-components-InputText-__InputText__addon {
  font-family: IBM Plex Sans,Lucida Grande,Tahoma,Verdana,Arial,sans-serif;
  color: var(--primary);
  font-size: 14px;
  line-height: 1.57;
  font-weight: normal
}
.quid-react-components__src-components-InputText-__InputText {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex
}
.quid-react-components__src-components-InputText-__InputText--large .quid-react-components__src-components-InputText-__InputText__input {
    height: 3.57em
}
.quid-react-components__src-components-InputText-__InputText--small .quid-react-components__src-components-InputText-__InputText__input {
    height: 1.71em
}
.quid-react-components__src-components-InputText-__InputText__input, .quid-react-components__src-components-InputText-__InputText__addon {
    /* Reset */
    font-size: 0.93em;
    font-family: inherit;
    font-weight: inherit;
    padding: inherit;

    /* Style */
    border: 1px solid var(--gray2);
    -webkit-transition: border .2s ease-in-out;
    -o-transition: border .2s ease-in-out;
    transition: border .2s ease-in-out;
    background-color: var(--white)
}
[data-whatinput="initial"] .quid-react-components__src-components-InputText-__InputText__input:focus, [data-whatinput="mouse"] .quid-react-components__src-components-InputText-__InputText__input:focus, [data-whatinput="initial"] .quid-react-components__src-components-InputText-__InputText__addon:focus, [data-whatinput="mouse"] .quid-react-components__src-components-InputText-__InputText__addon:focus {
      outline: none
}
.quid-react-components__src-components-InputText-__InputText__input {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    height: 2.45em;
    border-radius: 2px;
    padding-left: 0.71em;
    padding-right: 0.71em;
    min-width: 0;
    /* needed to remove :invalid red border in Firefox */
    -webkit-box-shadow: none;
            box-shadow: none
}
.quid-react-components__src-components-InputText-__InputText__input[type=number], .quid-react-components__src-components-InputText-__InputText__input[type=date] {
      -moz-appearance: textfield;
      -webkit-appearance: none
}
.quid-react-components__src-components-InputText-__InputText__input[type=number]::-webkit-outer-spin-button, .quid-react-components__src-components-InputText-__InputText__input[type=number]::-webkit-inner-spin-button, .quid-react-components__src-components-InputText-__InputText__input[type=number]::-webkit-calendar-picker-indicator, .quid-react-components__src-components-InputText-__InputText__input[type=number]::-webkit-clear-button, .quid-react-components__src-components-InputText-__InputText__input[type=date]::-webkit-outer-spin-button, .quid-react-components__src-components-InputText-__InputText__input[type=date]::-webkit-inner-spin-button, .quid-react-components__src-components-InputText-__InputText__input[type=date]::-webkit-calendar-picker-indicator, .quid-react-components__src-components-InputText-__InputText__input[type=date]::-webkit-clear-button {
        -webkit-appearance: none;
        appearance: none;
        margin: 0;
        display: none
}
.quid-react-components__src-components-InputText-__InputText__input--withAddon {
      border-right-width: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0
}
.quid-react-components__src-components-InputText-__InputText__input--alignRight {
      text-align: right
}
.quid-react-components__src-components-InputText-__InputText__input--noAddonPadding {
      padding-right: 0
}
[data-whatinput="initial"] .quid-react-components__src-components-InputText-__InputText__input:focus, [data-whatinput="mouse"] .quid-react-components__src-components-InputText-__InputText__input:focus, .quid-react-components__src-components-InputText-__InputText__input--focus {}
[data-whatinput="initial"] .quid-react-components__src-components-InputText-__InputText__input:focus, [data-whatinput="mouse"] .quid-react-components__src-components-InputText-__InputText__input:focus, [data-whatinput="initial"] .quid-react-components__src-components-InputText-__InputText__input:focus + .quid-react-components__src-components-InputText-__InputText__addon, [data-whatinput="mouse"] .quid-react-components__src-components-InputText-__InputText__input:focus + .quid-react-components__src-components-InputText-__InputText__addon, .quid-react-components__src-components-InputText-__InputText__input--focus, .quid-react-components__src-components-InputText-__InputText__input--focus + .quid-react-components__src-components-InputText-__InputText__addon {
        outline: 0;
        border-color: var(--selected)
}
.quid-react-components__src-components-InputText-__InputText__input--invalid {}
.quid-react-components__src-components-InputText-__InputText__input--invalid, .quid-react-components__src-components-InputText-__InputText__input--invalid:focus, [data-whatinput="initial"] .quid-react-components__src-components-InputText-__InputText__input--invalid:focus, [data-whatinput="mouse"] .quid-react-components__src-components-InputText-__InputText__input--invalid:focus {}
.quid-react-components__src-components-InputText-__InputText__input--invalid, .quid-react-components__src-components-InputText-__InputText__input--invalid + .quid-react-components__src-components-InputText-__InputText__addon, .quid-react-components__src-components-InputText-__InputText__input--invalid:focus, .quid-react-components__src-components-InputText-__InputText__input--invalid:focus + .quid-react-components__src-components-InputText-__InputText__addon, [data-whatinput="initial"] .quid-react-components__src-components-InputText-__InputText__input--invalid:focus, [data-whatinput="mouse"] .quid-react-components__src-components-InputText-__InputText__input--invalid:focus, [data-whatinput="initial"] .quid-react-components__src-components-InputText-__InputText__input--invalid:focus + .quid-react-components__src-components-InputText-__InputText__addon, [data-whatinput="mouse"] .quid-react-components__src-components-InputText-__InputText__input--invalid:focus + .quid-react-components__src-components-InputText-__InputText__addon {
          border-color: var(--red)
}
.quid-react-components__src-components-InputText-__InputText__input:disabled {}
.quid-react-components__src-components-InputText-__InputText__input:disabled, .quid-react-components__src-components-InputText-__InputText__input:disabled + .quid-react-components__src-components-InputText-__InputText__addon {
        background-color: var(--gray1);
        color: var(--gray3)
}
.quid-react-components__src-components-InputText-__InputText__addon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding-left: 0.37em;
    border-left-width: 0;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    padding-left: 0.71em;
    padding-right: 0.71em
}
.quid-react-components__src-components-InputText-__InputText__addon--noAddonPadding {
      padding-left: 0.09em
}
.quid-react-components__src-components-InputText-__InputText--groupChild {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1
}
.quid-react-components__src-components-InputText-__InputText--groupChild .quid-react-components__src-components-InputText-__InputText__input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0
}

.quid-react-components__src-components-ButtonMenu-__ButtonMenu--placeholder {
  color: var(--secondary)
}
.quid-react-components__src-components-ButtonMenu-__ButtonMenu {}
.quid-react-components__src-components-ButtonMenu-__ButtonMenu--readOnly {
    cursor: pointer
}
.quid-react-components__src-components-ButtonMenu-__ButtonMenu--button {
    margin-left: 0.19em;
    margin-right: 0.19em;
    margin-top: -0.37em;
    margin-bottom: -0.37em
}
.quid-react-components__src-components-ButtonMenu-__ButtonMenu__iconButton {
    cursor: pointer;
    color: var(--primary)
}
[data-whatinput="initial"] .quid-react-components__src-components-ButtonMenu-__ButtonMenu__iconButton:focus, [data-whatinput="mouse"] .quid-react-components__src-components-ButtonMenu-__ButtonMenu__iconButton:focus {
      outline: 0
}
.quid-react-components__src-components-ButtonMenu-__ButtonMenu__icon {
    margin-left: 0.46em
}
.quid-react-components__src-components-ButtonMenu-__ButtonMenu:disabled .quid-react-components__src-components-ButtonMenu-__ButtonMenu__icon {
    color: var(--secondary)
}
.quid-react-components__src-components-ButtonMenu-__ButtonMenu__buttonInner {
    text-transform: initial;
    font-weight: normal
}

.quid-react-components__src-components-Calendar-__Calendar {
  font-family: IBM Plex Sans,Lucida Grande,Tahoma,Verdana,Arial,sans-serif;
  color: var(--primary);
  font-size: 14px;
  line-height: 1.57;
  font-weight: normal
}
.quid-react-components__src-components-Calendar-__Calendar__year {
  font-family: IBM Plex Sans,Lucida Grande,Tahoma,Verdana,Arial,sans-serif;
  font-size: 16px;
  line-height: 1.5
}
.quid-react-components__src-components-Calendar-__Calendar {
  --cellHeight: 2.32em;
  --cellWidth: 3.25em;
  width: calc(var(--cellWidth) * 7);
  border-radius: 2px
}
[data-whatinput="initial"] .quid-react-components__src-components-Calendar-__Calendar:focus, [data-whatinput="mouse"] .quid-react-components__src-components-Calendar-__Calendar:focus {
    outline: 0
}
.quid-react-components__src-components-Calendar-__Calendar__header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding-top: 0.28em;
    padding-bottom: 0.28em
}
.quid-react-components__src-components-Calendar-__Calendar__year {
    margin-left: auto;
    margin-right: auto
}
.quid-react-components__src-components-Calendar-__Calendar__nav {
    font-size: 0.74em /* ~16px */
}
.quid-react-components__src-components-Calendar-__Calendar__calendar {
    color: var(--black);
    background-color: var(--white);
    border: 1px solid var(--gray2);
    border-top: 0px;
    border-radius: 0 0 2px 2px;
    padding: 0.93em
}
.quid-react-components__src-components-Calendar-__Calendar__table {
    text-align: center
}
.quid-react-components__src-components-Calendar-__Calendar__days:before {
    content: '';
    display: block;
    height: 0.46em
}
.quid-react-components__src-components-Calendar-__Calendar__action {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end
}
.quid-react-components__src-components-Calendar-__Calendar__select {
    margin-left: 0.71em
}

.quid-react-components__src-components-Calendar-Header-__Header {
  font-family: IBM Plex Sans,Lucida Grande,Tahoma,Verdana,Arial,sans-serif;
  color: var(--primary);
  font-size: 14px;
  line-height: 1.57;
  font-weight: normal
}
.quid-react-components__src-components-Calendar-Header-__Header__rest {
  font-family: IBM Plex Sans,Lucida Grande,Tahoma,Verdana,Arial,sans-serif;
  font-size: 20px;
  line-height: 1.4
}
.quid-react-components__src-components-Calendar-Header-__Header__year {
  font-family: IBM Plex Sans,Lucida Grande,Tahoma,Verdana,Arial,sans-serif;
  font-size: 16px;
  line-height: 1.5
}
.quid-react-components__src-components-Calendar-Header-__Header__year, .quid-react-components__src-components-Calendar-Header-__Header__rest {
  font-weight: bold
}
.quid-react-components__src-components-Calendar-Header-__Header {
  color: var(--white);
  background-color: var(--selected);
  padding: 1.43em;
  border-radius: 2px 2px 0 0
}
.quid-react-components__src-components-Calendar-Header-__Header__year {
    opacity: .7
}
.quid-react-components__src-components-Calendar-Header-__Header__rest {
    text-transform: uppercase
}

.quid-react-components__src-components-Calendar-Cell-__Cell--current, .quid-react-components__src-components-Calendar-Cell-__Cell--selected, .quid-react-components__src-components-Calendar-Cell-__Cell__button--selected {
  font-weight: bold
}
.quid-react-components__src-components-Calendar-Cell-__Cell--header {
  color: var(--secondary)
}
.quid-react-components__src-components-Calendar-Cell-__Cell {
  height: var(--cellHeight);
  width: var(--cellWidth);
  vertical-align: middle
}
.quid-react-components__src-components-Calendar-Cell-__Cell--current {
    color: var(--selected)
}
.quid-react-components__src-components-Calendar-Cell-__Cell--selected {
    background-color: var(--teal);
    border-radius: 100%
}
.quid-react-components__src-components-Calendar-Cell-__Cell__button {
    /* Reset */
    font: inherit;
    background: inherit;
    border: inherit;
    line-height: inherit;
    color: inherit;
    cursor: pointer;

    /* Style */
    width: var(--cellHeight);
    height: var(--cellHeight);
    border-radius: 100%;
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out
}
.quid-react-components__src-components-Calendar-Cell-__Cell__button--selected {
      background-color: var(--selected);
      color: var(--white)
}
.quid-react-components__src-components-Calendar-Cell-__Cell__button--disabled {
      color: var(--disabled)
}
[data-whatinput="initial"] .quid-react-components__src-components-Calendar-Cell-__Cell__button:focus, [data-whatinput="mouse"] .quid-react-components__src-components-Calendar-Cell-__Cell__button:focus {
      outline: 0
}
.quid-react-components__src-components-Calendar-Cell-__Cell__button:disabled {
      cursor: default
}

.quid-react-components__src-components-Separator-__Separator {
  border-width: 0;
  margin: 0;
  border-color: var(--gray1);
  width: 0;
  height: 0
}

  .quid-react-components__src-components-Separator-__Separator--horizontal {
    border-bottom-width: 1px;
    width: 100%
}

  .quid-react-components__src-components-Separator-__Separator--vertical {
    border-right-width: 1px;
    height: 100%
}

:root .quid-react-components__src-components-Card-__Card, [data-theme="light"] .quid-react-components__src-components-Card-__Card, [data-theme] [data-theme="light"] .quid-react-components__src-components-Card-__Card, [data-theme="light"].quid-react-components__src-components-Card-__Card, [data-theme] [data-theme="light"].quid-react-components__src-components-Card-__Card {
  --cardBackground: var(--white);
  --cardBoxShadow: 0 2px 2px 0 rgba(18, 18, 18, 0.2);
  --cardBorder: none;
  --cardTextColor: var(--primary);
  --cardSeparatorDisplay: block;
  --cardSideColor: transparent;
  --cardSideMargin: none;
  --cardSidePadding: 0 0 0 0;
  --cardSideWidth: auto;
  --skeletonBackground: var(--gray1)
}
[data-theme="dark"] .quid-react-components__src-components-Card-__Card, [data-theme] [data-theme="dark"] .quid-react-components__src-components-Card-__Card, [data-theme="dark"].quid-react-components__src-components-Card-__Card, [data-theme] [data-theme="dark"].quid-react-components__src-components-Card-__Card {
  --cardBackground: transparent;
  --cardBoxShadow: none;
  --cardBorder: 2px solid var(--gray6);
  --cardTextColor: var(--gray2);
  --cardSeparatorDisplay: none;
  --cardSideColor: var(--gray6);
  --cardSideMargin: -0.71em 0.71em -0.71em -0.71em;
  --cardSidePadding: 0.71em calc(0.71em / 2) 0.71em calc(0.71em / 2);
  --cardSideWidth: 2.60em;
  --skeletonBackground: var(--gray6)
}
.quid-react-components__src-components-Card-__Card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  background: var(--cardBackground);
  -webkit-box-shadow: var(--cardBoxShadow);
          box-shadow: var(--cardBoxShadow);
  border: var(--cardBorder);
  border-radius: 2px;
  padding: 0.71em;
  color: var(--cardTextColor)
}
.quid-react-components__src-components-Card-__Card__column {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    min-width: 0;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    word-break: break-word
}
.quid-react-components__src-components-Card-__Card__side {
    margin-left: auto;
    margin-right: 0
}
.quid-react-components__src-components-Card-__Card__side--left {
      margin-right: auto;
      margin-left: 0;
      background-color: var(--cardSideColor);
      margin: var(--cardSideMargin);
      padding: var(--cardSidePadding);
      width: var(--cardSideWidth);
      min-width: var(--cardSideWidth)
}
.quid-react-components__src-components-Card-__Card__separator {
    display: var(--cardSeparatorDisplay);
    margin-bottom: 0.71em;
    margin-top: 0.71em
}
.quid-react-components__src-components-Card-__Card__header {
    word-break: break-word
}
.quid-react-components__src-components-Card-__Card__headerRow {
    margin-bottom: 0.35em
}
.quid-react-components__src-components-Card-__Card__headerRow:last-child {
      margin-bottom: 0
}
.quid-react-components__src-components-Card-__Card__body {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    min-height: 0;
    word-break: break-word
}
.quid-react-components__src-components-Card-__Card--skeleton {
    background-color: var(--skeletonBackground);
    -webkit-box-shadow: none;
            box-shadow: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    border: 0
}

:root .quid-react-components__src-components-Carousel-Dot-__Carousel, [data-theme="light"] .quid-react-components__src-components-Carousel-Dot-__Carousel, [data-theme] [data-theme="light"] .quid-react-components__src-components-Carousel-Dot-__Carousel, [data-theme="light"].quid-react-components__src-components-Carousel-Dot-__Carousel {
  --stacked0: rgba(199, 204, 209, 1);
  --stacked0Hover: #E0E0E0;
  --stacked0Active: #B7B7B7;
  --stacked1: #DBDBDB;
  --stacked2: #EAEAEA
}
[data-theme="dark"] .quid-react-components__src-components-Carousel-Dot-__Carousel, [data-theme] [data-theme="dark"] .quid-react-components__src-components-Carousel-Dot-__Carousel, [data-theme="dark"].quid-react-components__src-components-Carousel-Dot-__Carousel {
  --stacked0: rgba(199, 204, 209, 1);
  --stacked0Hover: #E0E0E0;
  --stacked0Active: #B7B7B7;
  --stacked1: #7A7A7A;
  --stacked2: #3C3C3C
}
.quid-react-components__src-components-Carousel-Dot-__Carousel {
  height: 1.86em;
  overflow: hidden;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none
}
[data-whatinput="initial"] .quid-react-components__src-components-Carousel-Dot-__Carousel:focus, [data-whatinput="mouse"] .quid-react-components__src-components-Carousel-Dot-__Carousel:focus {
    outline: 0
}
.quid-react-components__src-components-Carousel-Dot-__Carousel__scroller {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    min-width: 0;
    overflow: hidden;
    padding-left: 2.04em;
    padding-right: 2.04em;
    -webkit-box-sizing: initial;
            box-sizing: initial
}
.quid-react-components__src-components-Carousel-Dot-__Carousel__scroller--noPadding {
      padding: 0
}
.quid-react-components__src-components-Carousel-Dot-__Carousel__list {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    list-style: none;
    will-change: transform;
    -webkit-transition: -webkit-transform .2s ease-in-out;
    transition: -webkit-transform .2s ease-in-out;
    -o-transition: transform .2s ease-in-out;
    transition: transform .2s ease-in-out;
    transition: transform .2s ease-in-out, -webkit-transform .2s ease-in-out
}
.quid-react-components__src-components-Carousel-Dot-__Carousel__navButton {}
.quid-react-components__src-components-Carousel-Dot-__Carousel__navButton--right {
      margin-left: 0.71em
}
.quid-react-components__src-components-Carousel-Dot-__Carousel__navButton--left {
      margin-right: 0.71em
}
:root .quid-react-components__src-components-Carousel-Dot-__CarouselDot, [data-theme="light"] .quid-react-components__src-components-Carousel-Dot-__CarouselDot, [data-theme] [data-theme="light"] .quid-react-components__src-components-Carousel-Dot-__CarouselDot, [data-theme="light"].quid-react-components__src-components-Carousel-Dot-__CarouselDot {
  --stacked1Filter: linear-gradient(to right, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3));
  --stacked2Filter: linear-gradient(to right, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6));
  --activeBorder: rgba(18, 18, 18, 1)
}
[data-theme="dark"] .quid-react-components__src-components-Carousel-Dot-__CarouselDot, [data-theme] [data-theme="dark"] .quid-react-components__src-components-Carousel-Dot-__CarouselDot, [data-theme="dark"].quid-react-components__src-components-Carousel-Dot-__CarouselDot {
  --stacked1Filter: linear-gradient(to right, rgba(18, 18, 18, 0.3), rgba(18, 18, 18, 0.3));
  --stacked2Filter: linear-gradient(to right, rgba(18, 18, 18, 0.5), rgba(18, 18, 18, 0.5));
  --activeBorder: rgba(255, 255, 255, 1)
}
.quid-react-components__src-components-Carousel-Dot-__CarouselDot {
  position: relative;
  z-index: 3;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  will-change: transform;
  -webkit-transition: all .2s ease-in-out, opacity .3s ease-in-out .1s;
  -o-transition: all .2s ease-in-out, opacity .3s ease-in-out .1s;
  transition: all .2s ease-in-out, opacity .3s ease-in-out .1s
}
.quid-react-components__src-components-Carousel-Dot-__CarouselDot--stacked2 {
    z-index: 1
}
.quid-react-components__src-components-Carousel-Dot-__CarouselDot--stacked2 .quid-react-components__src-components-Carousel-Dot-__CarouselDot__button {
      background-color: #EAEAEA;
      background-color: var(--stacked2)
}
.quid-react-components__src-components-Carousel-Dot-__CarouselDot--stacked2 .quid-react-components__src-components-Carousel-Dot-__CarouselDot__icon {
      opacity: .3
}
.quid-react-components__src-components-Carousel-Dot-__CarouselDot--stacked1 {
    z-index: 2
}
.quid-react-components__src-components-Carousel-Dot-__CarouselDot--stacked1 .quid-react-components__src-components-Carousel-Dot-__CarouselDot__button {
      background-color: #DBDBDB;
      background-color: var(--stacked1)
}
.quid-react-components__src-components-Carousel-Dot-__CarouselDot--stacked1 .quid-react-components__src-components-Carousel-Dot-__CarouselDot__icon {
      opacity: .4
}
.quid-react-components__src-components-Carousel-Dot-__CarouselDot--colorful {}
.quid-react-components__src-components-Carousel-Dot-__CarouselDot--colorful .quid-react-components__src-components-Carousel-Dot-__CarouselDot__button {
      position: relative;
      background-color: currentColor
}
/* them and transition between them with the opacity property */
.quid-react-components__src-components-Carousel-Dot-__CarouselDot--colorful .quid-react-components__src-components-Carousel-Dot-__CarouselDot__button::before, .quid-react-components__src-components-Carousel-Dot-__CarouselDot--colorful .quid-react-components__src-components-Carousel-Dot-__CarouselDot__button::after {
        content: '';
        position: absolute;
        border-radius: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        -webkit-transition: opacity .2s ease-in-out;
        -o-transition: opacity .2s ease-in-out;
        transition: opacity .2s ease-in-out;
        opacity: 0
}
/* don't support linear-gradient transitions, we have to overlap */
.quid-react-components__src-components-Carousel-Dot-__CarouselDot--colorful .quid-react-components__src-components-Carousel-Dot-__CarouselDot__button:hover {
        background-color: currentColor
}
.quid-react-components__src-components-Carousel-Dot-__CarouselDot--colorful .quid-react-components__src-components-Carousel-Dot-__CarouselDot__button:hover::after {
          opacity: 1;
          background-image: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0.4)), to(rgba(255, 255, 255, 0.4)));
          background-image: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4));
          background-image: -o-linear-gradient(left, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4));
          background-image: linear-gradient(to right, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4))
}
/* This mostrous mess is needed because our lovely browser vendors */
.quid-react-components__src-components-Carousel-Dot-__CarouselDot--colorful .quid-react-components__src-components-Carousel-Dot-__CarouselDot__button:active {
        background-color: currentColor
}
.quid-react-components__src-components-Carousel-Dot-__CarouselDot--colorful .quid-react-components__src-components-Carousel-Dot-__CarouselDot__button:active::before {
          opacity: 1;
          background-image: -webkit-gradient(linear, left top, right top, from(rgba(18, 18, 18, 0.1)), to(rgba(255, 255, 255, 0.1)));
          background-image: -webkit-linear-gradient(left, rgba(18, 18, 18, 0.1), rgba(255, 255, 255, 0.1));
          background-image: -o-linear-gradient(left, rgba(18, 18, 18, 0.1), rgba(255, 255, 255, 0.1));
          background-image: linear-gradient(to right, rgba(18, 18, 18, 0.1), rgba(255, 255, 255, 0.1))
}
.quid-react-components__src-components-Carousel-Dot-__CarouselDot--colorful .quid-react-components__src-components-Carousel-Dot-__CarouselDot__button:hover:active::after {
        opacity: 0
}
.quid-react-components__src-components-Carousel-Dot-__CarouselDot--colorful .quid-react-components__src-components-Carousel-Dot-__CarouselDot__button--active {
        -webkit-box-shadow: 0 0 0 2px var(--activeBorder);
                box-shadow: 0 0 0 2px var(--activeBorder)
}
.quid-react-components__src-components-Carousel-Dot-__CarouselDot--colorful.quid-react-components__src-components-Carousel-Dot-__CarouselDot--stacked1 .quid-react-components__src-components-Carousel-Dot-__CarouselDot__button {
      background-image: var(--stacked1Filter)
}
.quid-react-components__src-components-Carousel-Dot-__CarouselDot--colorful.quid-react-components__src-components-Carousel-Dot-__CarouselDot--stacked2 .quid-react-components__src-components-Carousel-Dot-__CarouselDot__button {
      background-image: var(--stacked2Filter)
}
.quid-react-components__src-components-Carousel-Dot-__CarouselDot__button {
    position: relative;
    border: 0;
    padding: 0;
    cursor: pointer;
    font-size: inherit;
    border-radius: 100%;
    width: 1.14em;
    height: 1.14em;
    background-color: rgba(199, 204, 209, 1);
    background-color: var(--stacked0);
    margin-left: 0.36em;
    margin-right: 0.36em;
    -webkit-transition: background-color .2s ease-in-out;
    -o-transition: background-color .2s ease-in-out;
    transition: background-color .2s ease-in-out
}
[data-whatinput="initial"] .quid-react-components__src-components-Carousel-Dot-__CarouselDot__button:focus, [data-whatinput="mouse"] .quid-react-components__src-components-Carousel-Dot-__CarouselDot__button:focus {
      outline: 0
}
.quid-react-components__src-components-Carousel-Dot-__CarouselDot__button:hover {
      background-color: #E0E0E0;
      background-color: var(--stacked0Hover)
}
.quid-react-components__src-components-Carousel-Dot-__CarouselDot__button:active {
      background-color: #B7B7B7;
      background-color: var(--stacked0Active)
}
.quid-react-components__src-components-Carousel-Dot-__CarouselDot__button--active {
      background-color: currentColor
}
.quid-react-components__src-components-Carousel-Dot-__CarouselDot__button--active:hover {
        background-color: currentColor
}
.quid-react-components__src-components-Carousel-Dot-__CarouselDot__inner {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center
}
.quid-react-components__src-components-Carousel-Dot-__CarouselDot__icon {
    font-size: 0.74em;
    color: rgba(248, 248, 248, 1);
    color: var(--white)
}

:root .quid-react-components__src-components-Carousel-__Carousel, [data-theme="light"] .quid-react-components__src-components-Carousel-__Carousel, [data-theme] [data-theme="light"] .quid-react-components__src-components-Carousel-__Carousel, [data-theme="light"].quid-react-components__src-components-Carousel-__Carousel {
  --stacked0: rgba(199, 204, 209, 1);
  --stacked0Hover: #E0E0E0;
  --stacked0Active: #B7B7B7;
  --stacked1: #DBDBDB;
  --stacked2: #EAEAEA
}
[data-theme="dark"] .quid-react-components__src-components-Carousel-__Carousel, [data-theme] [data-theme="dark"] .quid-react-components__src-components-Carousel-__Carousel, [data-theme="dark"].quid-react-components__src-components-Carousel-__Carousel {
  --stacked0: rgba(199, 204, 209, 1);
  --stacked0Hover: #E0E0E0;
  --stacked0Active: #B7B7B7;
  --stacked1: #7A7A7A;
  --stacked2: #3C3C3C
}
.quid-react-components__src-components-Carousel-__Carousel {
  height: 1.86em;
  overflow: hidden;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none
}
[data-whatinput="initial"] .quid-react-components__src-components-Carousel-__Carousel:focus, [data-whatinput="mouse"] .quid-react-components__src-components-Carousel-__Carousel:focus {
    outline: 0
}
.quid-react-components__src-components-Carousel-__Carousel__scroller {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    min-width: 0;
    overflow: hidden;
    padding-left: 2.04em;
    padding-right: 2.04em;
    -webkit-box-sizing: initial;
            box-sizing: initial
}
.quid-react-components__src-components-Carousel-__Carousel__scroller--noPadding {
      padding: 0
}
.quid-react-components__src-components-Carousel-__Carousel__list {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    list-style: none;
    will-change: transform;
    -webkit-transition: -webkit-transform .2s ease-in-out;
    transition: -webkit-transform .2s ease-in-out;
    -o-transition: transform .2s ease-in-out;
    transition: transform .2s ease-in-out;
    transition: transform .2s ease-in-out, -webkit-transform .2s ease-in-out
}
.quid-react-components__src-components-Carousel-__Carousel__navButton {}
.quid-react-components__src-components-Carousel-__Carousel__navButton--right {
      margin-left: 0.71em
}
.quid-react-components__src-components-Carousel-__Carousel__navButton--left {
      margin-right: 0.71em
}

.quid-react-components__src-components-CircularMetricPoint-__CircularMetricPoint {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  position: relative;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 100%
}
  .quid-react-components__src-components-CircularMetricPoint-__CircularMetricPoint__label {
    font-size: 0.23em
}

.quid-react-components__src-components-ColorPalettePicker-__ColorPalettePicker {
  width: calc((2.97em + 0.23em * 2) * 5);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap
}
.quid-react-components__src-components-ColorPalettePicker-__ColorPalettePicker__color, .quid-react-components__src-components-ColorPalettePicker-__ColorPalettePicker__input {
    /* Reset */
    padding: 0;
    font-size: inherit;

    /* Style */
    border-radius: 2px;
    height: 2.14em;
    margin: 0.23em
}
[data-whatinput="initial"] .quid-react-components__src-components-ColorPalettePicker-__ColorPalettePicker__color:focus, [data-whatinput="mouse"] .quid-react-components__src-components-ColorPalettePicker-__ColorPalettePicker__color:focus, [data-whatinput="initial"] .quid-react-components__src-components-ColorPalettePicker-__ColorPalettePicker__input:focus, [data-whatinput="mouse"] .quid-react-components__src-components-ColorPalettePicker-__ColorPalettePicker__input:focus {
      outline: 0
}
.quid-react-components__src-components-ColorPalettePicker-__ColorPalettePicker__color {
    background-color: currentColor;
    width: 2.97em;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    cursor: pointer;
    border: 1px solid var(--gray3)
}
.quid-react-components__src-components-ColorPalettePicker-__ColorPalettePicker__color--disabled {
      background-color: var(--gray1);
      border-color: var(--gray2)
}
.quid-react-components__src-components-ColorPalettePicker-__ColorPalettePicker__input {
    width: calc((2.97em + 0.23em) * 2)
}
.quid-react-components__src-components-ColorPalettePicker-__ColorPalettePicker__hex {
    width: 7.80em
}

.quid-react-components__src-components-InputColor-__InputColor {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex
}

  .quid-react-components__src-components-InputColor-__InputColor__button {
    position: relative;
    isolation: isolate;
    border-radius: 2px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

  .quid-react-components__src-components-InputColor-__InputColor__color {
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    cursor: pointer;
    width: 0;
    height: 0
}

  .quid-react-components__src-components-InputColor-__InputColor__icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    padding: 0 0.74em;
    background-color: var(--gray2);
    border-radius: 0 2px 2px 0;
    cursor: pointer
}

  .quid-react-components__src-components-InputColor-__InputColor__icon--disabled {
      background-color: var(--gray2);
      color: var(--gray3);
      cursor: default
}

  .quid-react-components__src-components-InputColor-__InputColor__hex {
    min-width: 0
}

.quid-react-components__src-components-InputGroup-__InputGroup {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch
}

  .quid-react-components__src-components-InputGroup-__InputGroup__input {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1
}

:root .quid-react-components__src-components-ContextMenu-__Menu, [data-theme="light"] .quid-react-components__src-components-ContextMenu-__Menu, [data-theme] [data-theme="light"] .quid-react-components__src-components-ContextMenu-__Menu, [data-theme="light"].quid-react-components__src-components-ContextMenu-__Menu {
  --highlightBackground: rgba(227, 230, 232, 0.5);
  --activeBackground: rgba(0, 167, 184, 0.3);
  --contextMenuBorder: var(--gray1)
}
[data-theme="dark"] .quid-react-components__src-components-ContextMenu-__Menu, [data-theme] [data-theme="dark"] .quid-react-components__src-components-ContextMenu-__Menu, [data-theme="dark"].quid-react-components__src-components-ContextMenu-__Menu {
  --highlightBackground: rgba(73, 83, 90, 0.5);
  --activeBackground: rgba(60, 135, 144, 0.4);
  --contextMenuBorder: var(--black)
}
.quid-react-components__src-components-ContextMenu-__ContextMenu {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column
}
.quid-react-components__src-components-ContextMenu-__ContextMenu__trigger {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column
}
.quid-react-components__src-components-ContextMenu-__ContextMenu__sub {
    width: 100%
}
.quid-react-components__src-components-ContextMenu-__Menu {
  list-style: none;
  font-size: 1rem;
  background-color: var(--primaryInverse);
  color: var(--primary);
  border: 1px solid var(--contextMenuBorder);
  border-radius: 2px;
  -webkit-box-shadow: 2px 2px 3px 0 hsla(0, 0%, 7%, .25);
          box-shadow: 2px 2px 3px 0 hsla(0, 0%, 7%, .25);
  width: 13.93em
}
.quid-react-components__src-components-ContextMenu-__Menu--sub {
    margin: -0.46em
}
.quid-react-components__src-components-ContextMenu-__Item {}
.quid-react-components__src-components-ContextMenu-__Item__button {
    /* Reset */
    background-color: transparent;
    border: 0;
    padding: 0;
    margin: 0;
    font-size: inherit;
    color: inherit;
    text-align: inherit;

    /* Style */
    display: block;
    width: 100%;
    padding-top: 0.28em;
    padding-bottom: 0.28em;
    padding-left: 0.65em;
    padding-right: 0.65em;
    cursor: pointer;
    -webkit-transition: background-color .2s ease-in-out;
    -o-transition: background-color .2s ease-in-out;
    transition: background-color .2s ease-in-out
}
[data-whatinput="initial"] .quid-react-components__src-components-ContextMenu-__Item__button:focus, [data-whatinput="mouse"] .quid-react-components__src-components-ContextMenu-__Item__button:focus {
      outline: 0
}
.quid-react-components__src-components-ContextMenu-__Item__button--disabled {
      cursor: default
}
[data-whatinput="keyboard"] .quid-react-components__src-components-ContextMenu-__Item:focus, .quid-react-components__src-components-ContextMenu-__Item--focus, .quid-react-components__src-components-ContextMenu-__Item:hover {
    background-color: var(--highlightBackground)
}
.quid-react-components__src-components-ContextMenu-__Item--active {
    background-color: var(--activeBackground)
}
.quid-react-components__src-components-ContextMenu-__Item--focus.quid-react-components__src-components-ContextMenu-__Item--disabled, .quid-react-components__src-components-ContextMenu-__Item--disabled {}
.quid-react-components__src-components-ContextMenu-__Item--focus.quid-react-components__src-components-ContextMenu-__Item--disabled, [data-whatinput="keyboard"] .quid-react-components__src-components-ContextMenu-__Item--focus.quid-react-components__src-components-ContextMenu-__Item--disabled:focus, .quid-react-components__src-components-ContextMenu-__Item--focus.quid-react-components__src-components-ContextMenu-__Item--disabled:hover, .quid-react-components__src-components-ContextMenu-__Item--disabled, [data-whatinput="keyboard"] .quid-react-components__src-components-ContextMenu-__Item--disabled:focus, .quid-react-components__src-components-ContextMenu-__Item--disabled:hover {
      background-color: transparent;
      cursor: default
}
.quid-react-components__src-components-ContextMenu-__Item--tall {
    padding-top: 0.44em; /* 7.5px */
    padding-bottom: 0.44em
}
.quid-react-components__src-components-ContextMenu-__Item__inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center
}
.quid-react-components__src-components-ContextMenu-__Item__text {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between
}
.quid-react-components__src-components-ContextMenu-__Item__side {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-right: 0.65em
}
.quid-react-components__src-components-ContextMenu-__Item__caret {
    margin-left: auto
}
.quid-react-components__src-components-ContextMenu-__Heading {
  padding: 0.65em
}
.quid-react-components__src-components-ContextMenu-__Divider {
  border: 0;
  border-top: 1px solid var(--highlightBackground);
  margin-left: 0.65em;
  margin-right: 0.65em
}


:root .quid-react-components__src-components-InputCheckbox-__InputCheckbox, [data-theme="light"] .quid-react-components__src-components-InputCheckbox-__InputCheckbox, [data-theme] [data-theme="light"] .quid-react-components__src-components-InputCheckbox-__InputCheckbox, [data-theme="light"].quid-react-components__src-components-InputCheckbox-__InputCheckbox {
  --inputCheckboxOff: var(--gray4)
}

[data-theme="dark"] .quid-react-components__src-components-InputCheckbox-__InputCheckbox, [data-theme] [data-theme="dark"] .quid-react-components__src-components-InputCheckbox-__InputCheckbox, [data-theme="dark"].quid-react-components__src-components-InputCheckbox-__InputCheckbox {
  --inputCheckboxOff: var(--gray2)
}

.quid-react-components__src-components-InputCheckbox-__InputCheckbox {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  position: relative;
  height: 0.72em;
  width: 0.72em
}

.quid-react-components__src-components-InputCheckbox-__InputCheckbox__input {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
    cursor: pointer
}

.quid-react-components__src-components-InputCheckbox-__InputCheckbox__icon {
    position: absolute;
    top: 0;
    left: 0;
    -webkit-transition: opacity .2s ease-in-out;
    -o-transition: opacity .2s ease-in-out;
    transition: opacity .2s ease-in-out;
    display: inline-block;
    line-height: 0.769;
    cursor: pointer
}

.quid-react-components__src-components-InputCheckbox-__InputCheckbox__icon--off {
      opacity: 1;
      color: var(--inputCheckboxOff)
}

.quid-react-components__src-components-InputCheckbox-__InputCheckbox__icon--on {
      opacity: 0;
      color: var(--selected)
}

.quid-react-components__src-components-InputCheckbox-__InputCheckbox__icon--disabled {
      color: var(--gray3)
}

.quid-react-components__src-components-InputCheckbox-__InputCheckbox__input:checked ~ .quid-react-components__src-components-InputCheckbox-__InputCheckbox__icon--off {
    opacity: 0
}

.quid-react-components__src-components-InputCheckbox-__InputCheckbox__input:checked ~ .quid-react-components__src-components-InputCheckbox-__InputCheckbox__icon--on {
    opacity: 1
}

:root .quid-react-components__src-components-InputRadio-__InputRadio, [data-theme="light"] .quid-react-components__src-components-InputRadio-__InputRadio, [data-theme] [data-theme="light"] .quid-react-components__src-components-InputRadio-__InputRadio, [data-theme="light"].quid-react-components__src-components-InputRadio-__InputRadio {
  --inputRadioOff: var(--gray4)
}

[data-theme="dark"] .quid-react-components__src-components-InputRadio-__InputRadio, [data-theme] [data-theme="dark"] .quid-react-components__src-components-InputRadio-__InputRadio, [data-theme="dark"].quid-react-components__src-components-InputRadio-__InputRadio {
  --inputRadioOff: var(--gray2)
}

.quid-react-components__src-components-InputRadio-__InputRadio {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  position: relative;
  height: 0.71em;
  width: 0.71em
}

.quid-react-components__src-components-InputRadio-__InputRadio__input {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
    cursor: pointer
}

.quid-react-components__src-components-InputRadio-__InputRadio__icon {
    position: absolute;
    top: 0;
    left: 0;
    -webkit-transition: opacity .2s ease-in-out;
    -o-transition: opacity .2s ease-in-out;
    transition: opacity .2s ease-in-out;
    display: inline-block;
    line-height: 0.769;
    cursor: pointer
}

.quid-react-components__src-components-InputRadio-__InputRadio__icon--off {
      opacity: 1;
      color: var(--inputRadioOff)
}

.quid-react-components__src-components-InputRadio-__InputRadio__icon--on {
      opacity: 0;
      color: var(--selected)
}

.quid-react-components__src-components-InputRadio-__InputRadio__icon--disabled {
      color: var(--gray3)
}

.quid-react-components__src-components-InputRadio-__InputRadio__input:checked ~ .quid-react-components__src-components-InputRadio-__InputRadio__icon--off {
    opacity: 0
}

.quid-react-components__src-components-InputRadio-__InputRadio__input:checked ~ .quid-react-components__src-components-InputRadio-__InputRadio__icon--on {
    opacity: 1
}

.quid-react-components__src-components-Text-__Text {
  font-family: IBM Plex Sans,Lucida Grande,Tahoma,Verdana,Arial,sans-serif;
  color: var(--primary);
  font-size: 14px;
  line-height: 1.57;
  font-weight: normal
}
.quid-react-components__src-components-Text-__Text--xlarge {
  font-family: IBM Plex Sans,Lucida Grande,Tahoma,Verdana,Arial,sans-serif;
  font-size: 20px;
  line-height: 1.4
}
.quid-react-components__src-components-Text-__Text--large {
  font-family: IBM Plex Sans,Lucida Grande,Tahoma,Verdana,Arial,sans-serif;
  font-size: 16px;
  line-height: 1.5
}
.quid-react-components__src-components-Text-__Text--normal {
  font-family: IBM Plex Sans,Lucida Grande,Tahoma,Verdana,Arial,sans-serif;
  font-size: 14px;
  line-height: 1.57
}
.quid-react-components__src-components-Text-__Text--title {
  font-family: IBM Plex Serif,Gudea,Lucida Grande,Tahoma,Verdana,Arial,sans-serif;
  font-size: 16px;
  line-height: 1.5
}
.quid-react-components__src-components-Text-__Text--body {
  font-family: IBM Plex Serif,Gudea,Lucida Grande,Tahoma,Verdana,Arial,sans-serif;
  font-size: 14px;
  line-height: 1.57
}
.quid-react-components__src-components-Text-__Text--caption {
  font-family: IBM Plex Sans,Lucida Grande,Tahoma,Verdana,Arial,sans-serif;
  font-size: 12px;
  line-height: 1.5
}
.quid-react-components__src-components-Text-__Text--bold {
  font-weight: bold
}
.quid-react-components__src-components-Text-__Text--regular {
  font-weight: normal
}
.quid-react-components__src-components-Text-__Text--secondary {
  color: var(--secondary)
}
.quid-react-components__src-components-Text-__Text--disabled {
  color: var(--disabled)
}
.quid-react-components__src-components-Text-__Text--link {
  color: var(--link)
}
.quid-react-components__src-components-Text-__Text--highlighted {
  background-color: var(--highlighted);
  -webkit-box-shadow: 0 0 0 .2em var(--highlighted);
          box-shadow: 0 0 0 .2em var(--highlighted)
}
.quid-react-components__src-components-Text-__Text--italic {
    font-style: italic
}
:root .quid-react-components__src-components-Text-__Card, :root .quid-react-components__src-components-Text-__Skeleton, [data-theme="light"] .quid-react-components__src-components-Text-__Skeleton, [data-theme] [data-theme="light"] .quid-react-components__src-components-Text-__Skeleton, [data-theme="light"].quid-react-components__src-components-Text-__Skeleton, [data-theme] [data-theme="light"].quid-react-components__src-components-Text-__Skeleton {
  --colorPrimary: var(--gray2);
  --colorPrimaryPulse: rgba(199, 204, 209, 0.5)
}
[data-theme="dark"] .quid-react-components__src-components-Text-__Skeleton, [data-theme] [data-theme="dark"] .quid-react-components__src-components-Text-__Skeleton, [data-theme="dark"].quid-react-components__src-components-Text-__Skeleton, [data-theme] [data-theme="dark"].quid-react-components__src-components-Text-__Skeleton {
  --colorPrimary: var(--gray7);
  --colorPrimaryPulse: rgba(27, 31, 33, 0.5)
}
.quid-react-components__src-components-Text-__Skeleton {}
.quid-react-components__src-components-Text-__Skeleton__text {
    display: inline-block;
    height: 1.11em;
    margin-right: 0.46em;
    background-color: var(--colorPrimary);
    -webkit-animation: quid-react-components__src-components-Text-__pulse 3s ease-in-out infinite;
            animation: quid-react-components__src-components-Text-__pulse 3s ease-in-out infinite;
    border-radius: 2px
}
.quid-react-components__src-components-Text-__Skeleton__text:last-child {
      margin-right: 0
}
@-webkit-keyframes quid-react-components__src-components-Text-__pulse {
  0% {
    background-color: var(--colorPrimary)
  }

  50% {
    background-color: var(--colorPrimaryPulse)
  }

  100% {
    background-color: var(--colorPrimary)
  }
}
@keyframes quid-react-components__src-components-Text-__pulse {
  0% {
    background-color: var(--colorPrimary)
  }

  50% {
    background-color: var(--colorPrimaryPulse)
  }

  100% {
    background-color: var(--colorPrimary)
  }
}

.quid-react-components__src-components-EditableTitle-__EditableTitle__text, .quid-react-components__src-components-EditableTitle-__EditableTitle__input {
  font-family: IBM Plex Sans,Lucida Grande,Tahoma,Verdana,Arial,sans-serif;
  color: var(--primary);
  font-size: 14px;
  line-height: 1.57;
  font-weight: normal
}
.quid-react-components__src-components-EditableTitle-__EditableTitle {
  position: relative
}
.quid-react-components__src-components-EditableTitle-__EditableTitle__outerInput {
    position: absolute;
    top: -0.59em;
    left: -0.46em;
    right: -0.46em
}
.quid-react-components__src-components-EditableTitle-__EditableTitle__text {
    width: 100%;
    line-height: 1.2;
    padding: 0 0.32em
}
.quid-react-components__src-components-EditableTitle-__EditableTitle__text--invisible {
      opacity: 0
}
.quid-react-components__src-components-EditableTitle-__EditableTitle__text--nowrap {
      -o-text-overflow: ellipsis;
         text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap
}
.quid-react-components__src-components-EditableTitle-__EditableTitle__input {
    text-align: inherit
}
.quid-react-components__src-components-EditableTitle-__EditableTitle__edit {
    position: absolute;
    top: 0;
    right: 0;
    margin: -0.33em;
    opacity: 0;
    -webkit-transition: opacity .2s ease-in-out;
    -o-transition: opacity .2s ease-in-out;
    transition: opacity .2s ease-in-out
}
.quid-react-components__src-components-EditableTitle-__EditableTitle__edit:focus {
      opacity: 1
}
.quid-react-components__src-components-EditableTitle-__EditableTitle:hover, .quid-react-components__src-components-EditableTitle-__EditableTitle:focus {}
.quid-react-components__src-components-EditableTitle-__EditableTitle:hover .quid-react-components__src-components-EditableTitle-__EditableTitle__edit, .quid-react-components__src-components-EditableTitle-__EditableTitle:focus .quid-react-components__src-components-EditableTitle-__EditableTitle__edit {
      opacity: 1
}

.quid-react-components__src-components-EntityList-__EntityList__header {
  font-family: IBM Plex Sans,Lucida Grande,Tahoma,Verdana,Arial,sans-serif;
  color: var(--primary);
  font-size: 14px;
  line-height: 1.57;
  font-weight: normal
}
.quid-react-components__src-components-EntityList-__EntityList__header {
  font-weight: bold
}
:root .quid-react-components__src-components-EntityList-__EntityList, [data-theme="light"] .quid-react-components__src-components-EntityList-__EntityList, [data-theme] [data-theme="light"] .quid-react-components__src-components-EntityList-__EntityList, [data-theme="light"].quid-react-components__src-components-EntityList-__EntityList {
  --mainColor: var(--white);
  --hover: var(--gray1)
}
[data-theme="dark"] .quid-react-components__src-components-EntityList-__EntityList, [data-theme] [data-theme="dark"] .quid-react-components__src-components-EntityList-__EntityList, [data-theme="dark"].quid-react-components__src-components-EntityList-__EntityList {
  --mainColor: var(--gray6);
  --hover: var(--gray7)
}
.quid-react-components__src-components-EntityList-__EntityList {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  color: var(--primary)
}
.quid-react-components__src-components-EntityList-__EntityList__header {
    background-color: var(--mainColor);
    padding-left: 0.57em;
    padding-right: 0.57em;
    margin-bottom: 1px;
    line-height: 2.5;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    border-radius: 2px 2px 0 0
}
.quid-react-components__src-components-EntityList-__EntityList__list {
    overflow: auto;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    min-height: 0;
    background-color: var(--mainColor);
    border-radius: 0 0 2px 2px
}
.quid-react-components__src-components-EntityList-__EntityList__entity {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    width: 100%
}
.quid-react-components__src-components-EntityList-__EntityList__entityButton {
    all: unset;
    width: 100%;
    padding: 0.56em 0;
    cursor: pointer;
    border-radius: 2px;
    -webkit-transition: .2s background-color ease-in-out;
    -o-transition: .2s background-color ease-in-out;
    transition: .2s background-color ease-in-out;
    margin-bottom: 1px
}
.quid-react-components__src-components-EntityList-__EntityList__entityButton:hover {
      background-color: var(--hover)
}
.quid-react-components__src-components-EntityList-__EntityList__entityButton--selected, .quid-react-components__src-components-EntityList-__EntityList__entityButton--selected:hover {
      background-color: rgba(30, 215, 209, 0.3)
}
.quid-react-components__src-components-EntityList-__EntityList__entityButton--last {
      margin-bottom: 0
}
.quid-react-components__src-components-EntityList-__EntityList__entityInner {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    text-align: left;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    min-width: 0;
    padding-left: 0.46em;
    padding-right: 0.46em
}
.quid-react-components__src-components-EntityList-__EntityList__label {
    text-transform: none;
    min-width: 0;
    line-height: 1.2
}
.quid-react-components__src-components-EntityList-__EntityList__label--ellipsis {
      overflow: hidden;
      white-space: nowrap;
      -o-text-overflow: ellipsis;
         text-overflow: ellipsis
}
.quid-react-components__src-components-EntityList-__EntityList__count {
    margin-left: auto;
    text-transform: uppercase;
    padding-left: 0.46em;
    opacity: 0;
    width: 0;
    -webkit-transition: opacity .2s ease-in-out;
    -o-transition: opacity .2s ease-in-out;
    transition: opacity .2s ease-in-out;
    overflow: hidden;
    -ms-flex-negative: 0;
        flex-shrink: 0
}
.quid-react-components__src-components-EntityList-__EntityList__entity:hover .quid-react-components__src-components-EntityList-__EntityList__count {
    width: auto;
    opacity: 1
}

.quid-react-components__src-components-Scroller-__Scroller {
  overflow: auto;
  min-height: 0;
  min-width: 0;
  background-color: var(--gray1);
  padding: 0.71em 0.71em 0.35em 0.71em
}

  .quid-react-components__src-components-Scroller-__Scroller__item {
    padding-bottom: 0.35em
}

.quid-react-components__src-components-Filmstrip-__Filmstrip, [data-theme="light"] .quid-react-components__src-components-Filmstrip-__Filmstrip, [data-theme] [data-theme="light"] .quid-react-components__src-components-Filmstrip-__Filmstrip, [data-theme="light"].quid-react-components__src-components-Filmstrip-__Filmstrip {
  --filmstripBorder: rgba(227, 230, 232, 0.9);
  --filmstripBackground: rgba(227, 230, 232, 0.9);
  --navigationBackground: rgba(227, 230, 232, 0.6)
}
[data-theme="dark"] .quid-react-components__src-components-Filmstrip-__Filmstrip, [data-theme] [data-theme="dark"] .quid-react-components__src-components-Filmstrip-__Filmstrip, [data-theme="dark"].quid-react-components__src-components-Filmstrip-__Filmstrip {
  --filmstripBackground: rgba(73, 83, 90, 0.8);
  --filmstripBorder: rgba(73, 83, 90, 0.9);
  --navigationBackground: rgba(73, 83, 90, 0.6)
}
.quid-react-components__src-components-Filmstrip-__Filmstrip {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  background-color: var(--filmstripBackground);
  border-color: var(--filmstripBorder);
  border-style: solid
}
.quid-react-components__src-components-Filmstrip-__Filmstrip--horizontal {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    border-top-width: 1px;
    border-bottom-width: 1px
}
.quid-react-components__src-components-Filmstrip-__Filmstrip--vertical {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    border-left-width: 1px;
    border-right-width: 1px
}
.quid-react-components__src-components-Filmstrip-__Filmstrip__main {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    min-width: 0;
    overflow: hidden
}
.quid-react-components__src-components-Filmstrip-__Filmstrip__main--horizontal {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row
}
.quid-react-components__src-components-Filmstrip-__Filmstrip__main--horizontal:before, .quid-react-components__src-components-Filmstrip-__Filmstrip__main--horizontal:after {
        content: '';
        display: block;
        width: 0.71em;
        -ms-flex-negative: 0;
            flex-shrink: 0
}
.quid-react-components__src-components-Filmstrip-__Filmstrip__main--vertical {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column
}
.quid-react-components__src-components-Filmstrip-__Filmstrip__navigation {
    /* Reset */
    font-size: inherit;
    font-family: inherit;
    background: inherit;
    padding: inherit;
    border: inherit;

    /* Style */
    opacity: 0;
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    z-index: 1;
    background-color: var(--navigationBackground);
    -webkit-transition: opacity .2s ease-out .2s;
    -o-transition: opacity .2s ease-out .2s;
    transition: opacity .2s ease-out .2s
}
.quid-react-components__src-components-Filmstrip-__Filmstrip__navigation--horizontal {
      top: 0;
      bottom: 0;
      width: 2.79em;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row
}
.quid-react-components__src-components-Filmstrip-__Filmstrip__navigation--vertical {
      left: 0;
      right: 0;
      height: 2.79em;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column
}
.quid-react-components__src-components-Filmstrip-__Filmstrip__navigation--left {
      left: 0
}
.quid-react-components__src-components-Filmstrip-__Filmstrip__navigation--right {
      right: 0
}
.quid-react-components__src-components-Filmstrip-__Filmstrip__navigation--top {
      top: 0
}
.quid-react-components__src-components-Filmstrip-__Filmstrip__navigation--bottom {
      bottom: 0
}
.quid-react-components__src-components-Filmstrip-__Filmstrip:hover .quid-react-components__src-components-Filmstrip-__Filmstrip__navigation, .quid-react-components__src-components-Filmstrip-__Filmstrip:focus .quid-react-components__src-components-Filmstrip-__Filmstrip__navigation {
    -webkit-transition-delay: .3s;
         -o-transition-delay: .3s;
            transition-delay: .3s;
    transition-timing: ease-in;
    opacity: 1
}

.quid-react-components__src-components-Filmstrip-Item-__Item {
  padding: 0.71em;
  -webkit-transition: background-color .2s ease-in-out;
  -o-transition: background-color .2s ease-in-out;
  transition: background-color .2s ease-in-out;
  cursor: pointer;
  -ms-flex-negative: 0;
      flex-shrink: 0
}
[data-whatinput="initial"] .quid-react-components__src-components-Filmstrip-Item-__Item:focus, [data-whatinput="mouse"] .quid-react-components__src-components-Filmstrip-Item-__Item:focus {
    outline: 0
}
[data-whatinput="keyboard"] .quid-react-components__src-components-Filmstrip-Item-__Item:focus, .quid-react-components__src-components-Filmstrip-Item-__Item:hover {
    background-color: rgba(199, 204, 209, 0.5)
}
.quid-react-components__src-components-Filmstrip-Item-__Item--active {
    background-color: rgba(0, 193, 187, 0.3)
}
.quid-react-components__src-components-Filmstrip-Item-__Item--active:hover, [data-whatinput="keyboard"] .quid-react-components__src-components-Filmstrip-Item-__Item--active:focus {
      background-color: rgba(0, 193, 187, 0.5)
}

:root .quid-react-components__src-components-FilterMenu-__FilterMenu, [data-theme="light"] .quid-react-components__src-components-FilterMenu-__FilterMenu, [data-theme] [data-theme="light"] .quid-react-components__src-components-FilterMenu-__FilterMenu, [data-theme="light"].quid-react-components__src-components-FilterMenu-__FilterMenu, [data-theme] [data-theme="light"].quid-react-components__src-components-FilterMenu-__FilterMenu, [data-theme="light"].quid-react-components__src-components-FilterMenu-__FilterMenu__list {
  --borderColor: var(--gray2);
  --categoryBackground: var(--gray1)
}
[data-theme="dark"] .quid-react-components__src-components-FilterMenu-__FilterMenu, [data-theme] [data-theme="dark"] .quid-react-components__src-components-FilterMenu-__FilterMenu, [data-theme="dark"].quid-react-components__src-components-FilterMenu-__FilterMenu, [data-theme] [data-theme="dark"].quid-react-components__src-components-FilterMenu-__FilterMenu, [data-theme="dark"].quid-react-components__src-components-FilterMenu-__FilterMenu__list {
  --borderColor: var(--gray6);
  --categoryBackground: var(--gray7)
}
.quid-react-components__src-components-FilterMenu-__FilterMenu {
  display: inline-block;
  position: relative
}
.quid-react-components__src-components-FilterMenu-__FilterMenu__popper {
    width: 100%;
    z-index: 1
}
.quid-react-components__src-components-FilterMenu-__FilterMenu__list {
    overflow: auto
}
.quid-react-components__src-components-FilterMenu-__FilterMenu__section {
    border-bottom: solid 1px var(--borderColor);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column
}
/* Added to support two-column FilterMenu layouts (such as Viz's SearchMenu) */
.quid-react-components__src-components-FilterMenu-__FilterMenu__section--twoColumn {
      border-right: solid 1px var(--borderColor);
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row
}
.quid-react-components__src-components-FilterMenu-__FilterMenu__section--twoColumn:last-child {
        border-bottom: none
}
.quid-react-components__src-components-FilterMenu-__FilterMenu__section--twoColumn .quid-react-components__src-components-FilterMenu-__FilterMenu__heading, .quid-react-components__src-components-FilterMenu-__FilterMenu__section--twoColumn .quid-react-components__src-components-FilterMenu-__FilterMenu__list {
        -webkit-box-flex: 0;
            -ms-flex-positive: 0;
                flex-grow: 0;
        -ms-flex-negative: 1;
            flex-shrink: 1
}
.quid-react-components__src-components-FilterMenu-__FilterMenu__section--twoColumn .quid-react-components__src-components-FilterMenu-__FilterMenu__heading {
        background-color: var(--categoryBackground);
        border-right: solid 1px var(--borderColor);
        -ms-flex-preferred-size: 35%;
            flex-basis: 35%
}
.quid-react-components__src-components-FilterMenu-__FilterMenu__section--twoColumn .quid-react-components__src-components-FilterMenu-__FilterMenu__list {
        -ms-flex-preferred-size: 65%;
            flex-basis: 65%
}
.quid-react-components__src-components-FilterMenu-__FilterMenu__section--twoColumn .quid-react-components__src-components-FilterMenu-__FilterMenu__item {
        border-bottom: solid 1px var(--borderColor)
}
.quid-react-components__src-components-FilterMenu-__FilterMenu__section--twoColumn .quid-react-components__src-components-FilterMenu-__FilterMenu__item:last-child {
          border-bottom: none
}

.quid-react-components__src-components-FilterMenu-Item-__Item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%
}

  .quid-react-components__src-components-FilterMenu-Item-__Item__label {
    /* nothing */
}

  .quid-react-components__src-components-FilterMenu-Item-__Item__count {
    margin-left: auto
}

.quid-react-components__src-components-Footer-__Footer {
  font-size: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 2.86em; /* 40px */
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch
}

.quid-react-components__src-components-Footer-Padder-__Padder {
  margin-left: 0.36em;
  margin-right: 0.36em
}

.quid-react-components__src-components-Footer-Padder-__Padder--first {
    margin-left: calc(0.36em * 2)
}

.quid-react-components__src-components-Footer-Padder-__Padder--last {
    margin-right: calc(0.36em * 2)
}

:root .quid-react-components__src-components-Footer-Slot-__Slot, [data-theme="light"] .quid-react-components__src-components-Footer-Slot-__Slot, [data-theme] [data-theme="light"] .quid-react-components__src-components-Footer-Slot-__Slot {
  --slotSeparatorColor: var(--gray1)
}

[data-theme="dark"] .quid-react-components__src-components-Footer-Slot-__Slot, [data-theme] [data-theme="dark"] .quid-react-components__src-components-Footer-Slot-__Slot {
  --slotSeparatorColor: var(--gray5)
}

.quid-react-components__src-components-Footer-Slot-__Slot {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center
}

.quid-react-components__src-components-Footer-Slot-__Slot--left {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start
}

.quid-react-components__src-components-Footer-Slot-__Slot--center {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center
}

.quid-react-components__src-components-Footer-Slot-__Slot--right {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end
}

.quid-react-components__src-components-Footer-Slot-__Slot--separated {
    border-color: var(--slotSeparatorColor);
    border-style: solid;
    border-left-width: 1px;
    border-right-width: 1px;
    padding-left: 0.36em;
    padding-right: 0.36em
}

.quid-react-components__src-components-Heatmap-__HeatMap {
  /* HeatMap code CSS */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  pointer-events: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  overflow-x: auto;
  color: var(--primary);
  -webkit-user-select: text;
     -moz-user-select: text;
      -ms-user-select: text;
          user-select: text;

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0
}
.quid-react-components__src-components-Heatmap-__HeatMap__header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    margin-bottom: 1px;
    padding-left: calc(105px + 1px);
    z-index: 2;
    position: relative
}
.quid-react-components__src-components-Heatmap-__HeatMap__row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
    margin-bottom: 1px;
    cursor: pointer;
    padding-left: calc(105px + 1px)
}
.quid-react-components__src-components-Heatmap-__HeatMap__row:before {
      content: '';
      position: absolute;
      z-index: 2;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      -webkit-box-shadow: inset 0 0 0 0 var(--primary);
              box-shadow: inset 0 0 0 0 var(--primary);
      -webkit-transition: -webkit-box-shadow .2s ease-in-out;
      transition: -webkit-box-shadow .2s ease-in-out;
      -o-transition: box-shadow .2s ease-in-out;
      transition: box-shadow .2s ease-in-out;
      transition: box-shadow .2s ease-in-out, -webkit-box-shadow .2s ease-in-out;
      pointer-events: none
}
/* Focus effect affects the row and its content */
.quid-react-components__src-components-Heatmap-__HeatMap__row:focus {
      outline: 0;
      z-index: 2
}
.quid-react-components__src-components-Heatmap-__HeatMap__row:focus:before {
        -webkit-box-shadow: inset 0 0 0 1px var(--primary);
                box-shadow: inset 0 0 0 1px var(--primary)
}
.quid-react-components__src-components-Heatmap-__HeatMap__row--selected:before, .quid-react-components__src-components-Heatmap-__HeatMap__row--selected:focus:before {
      -webkit-box-shadow: inset 0 0 0 calc(1px * 2) var(--primary);
              box-shadow: inset 0 0 0 calc(1px * 2) var(--primary)
}
.quid-react-components__src-components-Heatmap-__HeatMap__resizeAware {
    position: relative;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1
}
.quid-react-components__src-components-Heatmap-__HeatMap__list {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    will-change: width
}
.quid-react-components__src-components-Heatmap-__HeatMapCell {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background-color: var(--primaryInverse);
  margin-right: 1px;
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  will-change: scroll-position;
  -webkit-transition: opacity .2s ease-in-out;
  -o-transition: opacity .2s ease-in-out;
  transition: opacity .2s ease-in-out;
  letter-spacing: .05rem;
  line-height: 1.43em
}
/* 20px on 13px base */
.quid-react-components__src-components-Heatmap-__HeatMap__cellWrapper:last-child .quid-react-components__src-components-Heatmap-__HeatMapCell {
    margin-right: 0
}
.quid-react-components__src-components-Heatmap-__HeatMapCell--header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    position: relative;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    height: 76px;
    background-color: transparent;
    line-height: 1.385rem
}
/* 18px on 13px base */
.quid-react-components__src-components-Heatmap-__HeatMapCell--header:first-child {
      background-image: none;
      position: absolute
}
.quid-react-components__src-components-Heatmap-__HeatMapCell--leading {
    position: absolute;
    z-index: 1;
    width: 105px;
    min-width: 105px;
    -webkit-box-flex: initial;
        -ms-flex: initial;
            flex: initial;
    margin-left: -(105px + 1px);
    background-color: var(--primaryInverse);
    background-image: -webkit-gradient(linear, left top, right top, from(currentColor), to(currentColor));
    background-image: -webkit-linear-gradient(left, currentColor, currentColor);
    background-image: -o-linear-gradient(left, currentColor, currentColor);
    background-image: linear-gradient(to right, currentColor, currentColor);
    background-size: .4rem 100%;
    background-repeat: no-repeat;
    background-position: -.4rem;
    /*transition: background-size .3s ease-in-out, */
    /*            font-weight .3s ease-in-out, */
    /*            opacity .2s ease-in-out */
    border-left: .4rem solid transparent;
    height: 100%;
    will-change: transform;
    color: var(--primary);
    line-height: 1.385rem
}
/* 18px on 13px base */
.quid-react-components__src-components-Heatmap-__HeatMapCell--leading:after {
      content: '';
      position: absolute;
      left: -.4rem;
      height: 100%;
      border-left: 0 solid var(--primary);
      -webkit-transition: border-width .2s ease-in-out;
      -o-transition: border-width .2s ease-in-out;
      transition: border-width .2s ease-in-out
}
.quid-react-components__src-components-Heatmap-__HeatMap__row:focus .quid-react-components__src-components-Heatmap-__HeatMapCell--leading:after {
      border-left-width: 1px
}
.quid-react-components__src-components-Heatmap-__HeatMap__row--selected .quid-react-components__src-components-Heatmap-__HeatMapCell--leading, .quid-react-components__src-components-Heatmap-__HeatMap__row--selected:focus .quid-react-components__src-components-Heatmap-__HeatMapCell--leading {
      /*background-size: calc(100% + .4rem) 100% */
      font-weight: 600
}
.quid-react-components__src-components-Heatmap-__HeatMap__row--selected .quid-react-components__src-components-Heatmap-__HeatMapCell--leading:after, .quid-react-components__src-components-Heatmap-__HeatMap__row--selected:focus .quid-react-components__src-components-Heatmap-__HeatMapCell--leading:after {
        border-left-width: 2px
}
.quid-react-components__src-components-Heatmap-__HeatMapCell__title {
    position: relative;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    width: 100%;
    background-color: var(--primaryInverse);
    margin-right: 0
}
.quid-react-components__src-components-Heatmap-__HeatMapCell__variations {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    width: 100%;
    margin-top: 1px
}
.quid-react-components__src-components-Heatmap-__HeatMapCell__variation {
    position: relative;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    background-color: var(--primaryInverse);
    min-width: 0;
    padding: .3rem 0
}
.quid-react-components__src-components-Heatmap-__HeatMapCell__variation:first-child {
      margin-left: 0
}
.quid-react-components__src-components-Heatmap-__HeatMapCell__variation:last-child {
      margin-right: 0
}
.quid-react-components__src-components-Heatmap-__HeatMapCell__text {
    text-align: center;
    overflow: hidden;
    -o-text-overflow: ellipsis;
       text-overflow: ellipsis;
    max-height: 100%;
    color: var(--white);
    padding: 0 .4rem;
    width: 100%
}
.quid-react-components__src-components-Heatmap-__HeatMapCell--leading .quid-react-components__src-components-Heatmap-__HeatMapCell__text, .quid-react-components__src-components-Heatmap-__HeatMapCell--header .quid-react-components__src-components-Heatmap-__HeatMapCell__text {
    color: var(--primary)
}
.quid-react-components__src-components-Heatmap-__HeatMapCell__dropdown {
    position: absolute;
    top: 0;
    right: 0;
    /* `!important` used as workaround until we convert this to CSS-in-JS */
    /* or in any case we refactor this component */
    width: 1.67em !important;
    height: 100% !important;
    background-color: transparent;
    color: transparent;
    cursor: pointer;
    -webkit-transition: background-color .3s ease-in-out,
                color .3s ease-in-out;
    -o-transition: background-color .3s ease-in-out,
                color .3s ease-in-out;
    transition: background-color .3s ease-in-out,
                color .3s ease-in-out
}
.quid-react-components__src-components-Heatmap-__HeatMapCell__dropdownWrapper {
    position: absolute;
    right: 0;
    width: 1.67em;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end
}
.quid-react-components__src-components-Heatmap-__HeatMapCell__variation:hover .quid-react-components__src-components-Heatmap-__HeatMapCell__dropdown, .quid-react-components__src-components-Heatmap-__HeatMapCell__title:hover .quid-react-components__src-components-Heatmap-__HeatMapCell__dropdown, .quid-react-components__src-components-Heatmap-__HeatMapCell__dropdown:focus {
    background-color: rgba(82, 82, 82, .7);
    color: var(--primary);
    outline: 0
}

.quid-react-components__src-components-HorizontalSpinner-__HorizontalSpinner__label {
  font-family: IBM Plex Sans,Lucida Grande,Tahoma,Verdana,Arial,sans-serif;
  color: var(--primary);
  font-size: 14px;
  line-height: 1.57;
  font-weight: normal
}
.quid-react-components__src-components-HorizontalSpinner-__HorizontalSpinner__label {
  font-family: IBM Plex Sans,Lucida Grande,Tahoma,Verdana,Arial,sans-serif;
  font-size: 16px;
  line-height: 1.5
}
.quid-react-components__src-components-HorizontalSpinner-__HorizontalSpinner__label {
  font-weight: bold
}
/* 20px */
.quid-react-components__src-components-HorizontalSpinner-__HorizontalSpinner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column
}
.quid-react-components__src-components-HorizontalSpinner-__HorizontalSpinner__dots {
    position: relative;
    height: calc(1.43em * 1.2);
    margin: 0.46em
}
.quid-react-components__src-components-HorizontalSpinner-__HorizontalSpinner__dot {
    position: absolute;
    top: 0;
    left: 0;
    width: 1.43em;
    height: 1.43em;
    border-radius: 100%;
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    -webkit-animation: quid-react-components__src-components-HorizontalSpinner-__dots ease-in-out 3s infinite;
            animation: quid-react-components__src-components-HorizontalSpinner-__dots ease-in-out 3s infinite;
    will-change: left, transform
}
.quid-react-components__src-components-HorizontalSpinner-__HorizontalSpinner__dot--bordered {
      border: 2px solid var(--background)
}
.quid-react-components__src-components-HorizontalSpinner-__HorizontalSpinner__dot--n0 {
      background-color: var(--teal);
      -webkit-animation-delay: .5s;
              animation-delay: .5s
}
.quid-react-components__src-components-HorizontalSpinner-__HorizontalSpinner__dot--n1 {
      background-color: var(--yellow);
      -webkit-animation-delay: .4s;
              animation-delay: .4s
}
.quid-react-components__src-components-HorizontalSpinner-__HorizontalSpinner__dot--n2 {
      background-color: var(--purple);
      -webkit-animation-delay: .3s;
              animation-delay: .3s
}
.quid-react-components__src-components-HorizontalSpinner-__HorizontalSpinner__dot--n3 {
      -webkit-animation-delay: .2s;
              animation-delay: .2s;
      background-color: var(--lime)
}
.quid-react-components__src-components-HorizontalSpinner-__HorizontalSpinner__dot--n4 {
      background-color: var(--coral);
      -webkit-animation-delay: .1s;
              animation-delay: .1s
}
.quid-react-components__src-components-HorizontalSpinner-__HorizontalSpinner__dot--n5 {
      background-color: var(--aqua);
      -webkit-animation-delay: 0s;
              animation-delay: 0s
}
.quid-react-components__src-components-HorizontalSpinner-__HorizontalSpinner__label {
    text-align: center
}
.quid-react-components__src-components-HorizontalSpinner-__HorizontalSpinner__label:after {
      content: '';
      position: absolute;
      -webkit-animation: quid-react-components__src-components-HorizontalSpinner-__ellipsis 3s infinite;
              animation: quid-react-components__src-components-HorizontalSpinner-__ellipsis 3s infinite
}
@-webkit-keyframes quid-react-components__src-components-HorizontalSpinner-__dots {
  15% {
    left: 0;
    -webkit-transform: scale(1) translateZ(0);
            transform: scale(1) translateZ(0)
  }
  30% {
    -webkit-transform: scale(.9) translateZ(0);
            transform: scale(.9) translateZ(0)
  }
  45% {
    left: calc(100% - 1.43em);
    -webkit-transform: scale(1) translateZ(0);
            transform: scale(1) translateZ(0)
  }
  65% {
    left: calc(100% - 1.43em);
    -webkit-transform: scale(1) translateZ(0);
            transform: scale(1) translateZ(0)
  }
  80% {
    -webkit-transform: scale(.9) translateZ(0);
            transform: scale(.9) translateZ(0)
  }
  95% {
    left: 0;
    -webkit-transform: scale(1) translateZ(0);
            transform: scale(1) translateZ(0)
  }
}
@keyframes quid-react-components__src-components-HorizontalSpinner-__dots {
  15% {
    left: 0;
    -webkit-transform: scale(1) translateZ(0);
            transform: scale(1) translateZ(0)
  }
  30% {
    -webkit-transform: scale(.9) translateZ(0);
            transform: scale(.9) translateZ(0)
  }
  45% {
    left: calc(100% - 1.43em);
    -webkit-transform: scale(1) translateZ(0);
            transform: scale(1) translateZ(0)
  }
  65% {
    left: calc(100% - 1.43em);
    -webkit-transform: scale(1) translateZ(0);
            transform: scale(1) translateZ(0)
  }
  80% {
    -webkit-transform: scale(.9) translateZ(0);
            transform: scale(.9) translateZ(0)
  }
  95% {
    left: 0;
    -webkit-transform: scale(1) translateZ(0);
            transform: scale(1) translateZ(0)
  }
}
@-webkit-keyframes quid-react-components__src-components-HorizontalSpinner-__ellipsis {
  0% {
    content: ''
  }
  25% {
    content: '.'
  }
  50% {
    content: '..'
  }
  75% {
    content: '...'
  }
}
@keyframes quid-react-components__src-components-HorizontalSpinner-__ellipsis {
  0% {
    content: ''
  }
  25% {
    content: '.'
  }
  50% {
    content: '..'
  }
  75% {
    content: '...'
  }
}

.quid-react-components__src-components-InputAutosize-__InputAutosize {
  display: block;
  position: relative
}

  .quid-react-components__src-components-InputAutosize-__InputAutosize__sizer {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    pointer-events: none;
    white-space: pre
}

  .quid-react-components__src-components-InputAutosize-__InputAutosize__input {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 100%;
    height: 100%
}

.quid-react-components__src-components-InputDate-__InputDate {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex
}

  .quid-react-components__src-components-InputDate-__InputDate__calendar {
    margin-top: 0.14em;
    margin-bottom: 0.14em
}

  .quid-react-components__src-components-InputDate-__InputDate__icon {
    font-size: 1.43em
}

  .quid-react-components__src-components-InputDate-__InputDate__input {
    width: 100%
}

  /* This is needed only in Firefox (especially 58 or higher) */

  /* but should gracefully degrade on lower Firefox versions */

  @-moz-document url-prefix() {
  .quid-react-components__src-components-InputDate-__InputDate__inputFormElement {
    clip-path: inset(0 1.86em 0 0);
    margin-right: -1.86em
  }
}

.quid-react-components__src-components-InputNumber-__InputNumber {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column
}
  .quid-react-components__src-components-InputNumber-__InputNumber__button {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    line-height: 0;
    width: 2.14em
}
  .quid-react-components__src-components-InputNumber-__InputNumber__button--up {
      margin-bottom: 1px
}

:root .quid-react-components__src-components-InputRange-__InputRange, [data-theme="light"] .quid-react-components__src-components-InputRange-__InputRange, [data-theme="light"].quid-react-components__src-components-InputRange-__InputRange, [data-theme] [data-theme="light"] .quid-react-components__src-components-InputRange-__InputRange {
  --trackBackground: var(--gray4)
}
:root .quid-react-components__src-components-InputRange-__InputRange__track--disabled, :root .quid-react-components__src-components-InputRange-__InputRange__handle--disabled, [data-theme="light"] .quid-react-components__src-components-InputRange-__InputRange__track--disabled, [data-theme="light"] .quid-react-components__src-components-InputRange-__InputRange__handle--disabled, [data-theme="light"].quid-react-components__src-components-InputRange-__InputRange__track--disabled, [data-theme="light"].quid-react-components__src-components-InputRange-__InputRange__handle--disabled, [data-theme] [data-theme="light"] .quid-react-components__src-components-InputRange-__InputRange__track--disabled, [data-theme] [data-theme="light"] .quid-react-components__src-components-InputRange-__InputRange__handle--disabled {
    --trackBackground: var(--gray3)
}
[data-theme="dark"] .quid-react-components__src-components-InputRange-__InputRange, [data-theme="dark"].quid-react-components__src-components-InputRange-__InputRange, [data-theme] [data-theme="dark"] .quid-react-components__src-components-InputRange-__InputRange {
  --trackBackground: var(--gray2)
}
[data-theme="dark"] .quid-react-components__src-components-InputRange-__InputRange__track--disabled, [data-theme="dark"] .quid-react-components__src-components-InputRange-__InputRange__handle--disabled, [data-theme="dark"].quid-react-components__src-components-InputRange-__InputRange__track--disabled, [data-theme="dark"].quid-react-components__src-components-InputRange-__InputRange__handle--disabled, [data-theme] [data-theme="dark"] .quid-react-components__src-components-InputRange-__InputRange__track--disabled, [data-theme] [data-theme="dark"] .quid-react-components__src-components-InputRange-__InputRange__handle--disabled {
    --trackBackground: var(--gray3)
}
.quid-react-components__src-components-InputRange-__InputRange {
  --handleSize: 0.43em;
  --handlePadding: 0.43em / 2;
  position: relative;
  height: calc(var(--handleSize) * 2);
  isolation: isolate
}
.quid-react-components__src-components-InputRange-__InputRange__track {
    position: relative;
    top: 50%;
    background-position: center;
    background-size: 100% 2px;
    background-repeat: no-repeat;
    height: 2px;
    border-radius: 2px;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%)
}
.quid-react-components__src-components-InputRange-__InputRange__track::before {
      content: '';
      position: absolute;
      height: var(--handleSize);
      width: 100%;
      -webkit-transform: translateY(-25%);
          -ms-transform: translateY(-25%);
              transform: translateY(-25%)
}
.quid-react-components__src-components-InputRange-__InputRange__track--disabled {
      --handleSize: calc(0.43em / 1.5);
      --selected: var(--trackBackground)
}
.quid-react-components__src-components-InputRange-__InputRange__segment {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100%;
    color: var(--trackBackground)
}
.quid-react-components__src-components-InputRange-__InputRange__segment--on {
      color: var(--selected)
}
.quid-react-components__src-components-InputRange-__InputRange--dragging1 .quid-react-components__src-components-InputRange-__InputRange__segment, .quid-react-components__src-components-InputRange-__InputRange--dragging2 .quid-react-components__src-components-InputRange-__InputRange__segment {
    /*transition: left .2s ease-in-out */
}
.quid-react-components__src-components-InputRange-__InputRange__handle {
    top: 50%;
    left: 0;
    position: absolute;
    width: var(--handleSize);
    height: var(--handleSize);
    margin-top: calc(var(--handleSize) / 2 * -1);
    cursor: default;
    z-index: 2
}
.quid-react-components__src-components-InputRange-__InputRange__handle::before {
      content: '';
      display: block;
      background-color: currentColor;
      width: var(--handleSize);
      height: var(--handleSize);
      -webkit-transform: scale(2);
          -ms-transform: scale(2);
              transform: scale(2);
      border-radius: 100%;
      -webkit-transition: width .2s ease-in-out,
                  height .2s ease-in-out,
                  -webkit-transform .2s ease-in-out;
      transition: width .2s ease-in-out,
                  height .2s ease-in-out,
                  -webkit-transform .2s ease-in-out;
      -o-transition: transform .2s ease-in-out,
                  width .2s ease-in-out,
                  height .2s ease-in-out;
      transition: transform .2s ease-in-out,
                  width .2s ease-in-out,
                  height .2s ease-in-out;
      transition: transform .2s ease-in-out,
                  width .2s ease-in-out,
                  height .2s ease-in-out,
                  -webkit-transform .2s ease-in-out
}
.quid-react-components__src-components-InputRange-__InputRange__handle:active::before, .quid-react-components__src-components-InputRange-__InputRange__handle--dragging::before {
      -webkit-transform: scale(3);
          -ms-transform: scale(3);
              transform: scale(3);
      -webkit-transition-delay: 0s;
           -o-transition-delay: 0s;
              transition-delay: 0s
}
.quid-react-components__src-components-InputRange-__InputRange__handle--disabled {
      --handleSize: calc(0.43em / 1.5);
      --handleSizePadding: calc(0.43em / 1.5)
}
.quid-react-components__src-components-InputRange-__InputRange__handle--disabled::before {
        background-color: var(--trackBackground)
}
.quid-react-components__src-components-InputRange-__InputRange__handle--disabled:active::before {
        -webkit-transform: scale(2);
            -ms-transform: scale(2);
                transform: scale(2)
}
.quid-react-components__src-components-InputRange-__InputRange__handle--zero::before {
      background-color: transparent;
      border: 1px solid var(--trackBackground);
      -webkit-transform: scale(2);
          -ms-transform: scale(2);
              transform: scale(2)
}
.quid-react-components__src-components-InputRange-__InputRange__handle:focus {
      outline: 0
}
[data-whatinput="keyboard"] .quid-react-components__src-components-InputRange-__InputRange__handle:focus::before {
      -webkit-animation: quid-react-components__src-components-InputRange-__pulse 1s infinite alternate ease-in-out;
              animation: quid-react-components__src-components-InputRange-__pulse 1s infinite alternate ease-in-out
}
@-webkit-keyframes quid-react-components__src-components-InputRange-__pulse {
  from {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 167, 184, 0.26);
            box-shadow: 0 0 0 0 rgba(0, 167, 184, 0.26)
  }
  to {
    -webkit-box-shadow: 0 0 0 calc(var(--handleSize) / 2) rgba(0, 167, 184, .26);
            box-shadow: 0 0 0 calc(var(--handleSize) / 2) rgba(0, 167, 184, .26)
  }
}
@keyframes quid-react-components__src-components-InputRange-__pulse {
  from {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 167, 184, 0.26);
            box-shadow: 0 0 0 0 rgba(0, 167, 184, 0.26)
  }
  to {
    -webkit-box-shadow: 0 0 0 calc(var(--handleSize) / 2) rgba(0, 167, 184, .26);
            box-shadow: 0 0 0 calc(var(--handleSize) / 2) rgba(0, 167, 184, .26)
  }
}
.quid-react-components__src-components-InputRange-__StepTicks {
  pointer-events: none;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between
}
.quid-react-components__src-components-InputRange-__StepTicks__tick {
    --tickHeight: 0.43em;
    --tickWidth: 0.14em;
    height: var(--tickHeight);
    width: var(--tickWidth);
    position: relative;
    left: calc(var(--tickWidth) / 2);
    top: calc(var(--tickHeight) / 2);
    color: var(--selected);
    border-radius: var(--tickWidth);
    background-color: var(--trackBackground)
}
.quid-react-components__src-components-InputRange-__StepTicks__tick:first-child {
      left: 0
}
.quid-react-components__src-components-InputRange-__StepTicks__tick--hidden {
      opacity: 0
}

.quid-react-components__src-components-Label-__Label {
  font-family: IBM Plex Sans,Lucida Grande,Tahoma,Verdana,Arial,sans-serif;
  color: var(--primary);
  font-size: 14px;
  line-height: 1.57;
  font-weight: normal
}
.quid-react-components__src-components-Label-__Label--secondary {
  color: var(--secondary)
}
.quid-react-components__src-components-Label-__Label--disabled {
  color: var(--disabled)
}
.quid-react-components__src-components-Label-__Label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 0.74em;
  margin-top: 1.49em
}
.quid-react-components__src-components-Label-__Label--inline {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    margin-top: 0;
    margin-bottom: 0
}
.quid-react-components__src-components-Label-__Label--noMargin {
    margin: 0
}
.quid-react-components__src-components-Label-__Label__input {
    margin-right: 0.71em
}

/* 5px */ /* 10px */ /* 20px */ .quid-react-components__src-components-Margin-__Margin {} .quid-react-components__src-components-Margin-__Margin__all {} .quid-react-components__src-components-Margin-__Margin__all--small {
      margin: 0.36em
} .quid-react-components__src-components-Margin-__Margin__all--regular {
      margin: 0.71em
} .quid-react-components__src-components-Margin-__Margin__all--large {
      margin: 1.43em
} .quid-react-components__src-components-Margin-__Margin__top {} .quid-react-components__src-components-Margin-__Margin__top--small {
      margin-top: 0.36em
} .quid-react-components__src-components-Margin-__Margin__top--regular {
      margin-top: 0.71em
} .quid-react-components__src-components-Margin-__Margin__top--large {
      margin-top: 1.43em
} .quid-react-components__src-components-Margin-__Margin__right {} .quid-react-components__src-components-Margin-__Margin__right--small {
      margin-right: 0.36em
} .quid-react-components__src-components-Margin-__Margin__right--regular {
      margin-right: 0.71em
} .quid-react-components__src-components-Margin-__Margin__right--large {
      margin-right: 1.43em
} .quid-react-components__src-components-Margin-__Margin__bottom {} .quid-react-components__src-components-Margin-__Margin__bottom--small {
      margin-bottom: 0.36em
} .quid-react-components__src-components-Margin-__Margin__bottom--regular {
      margin-bottom: 0.71em
} .quid-react-components__src-components-Margin-__Margin__bottom--large {
      margin-bottom: 1.43em
} .quid-react-components__src-components-Margin-__Margin__left {} .quid-react-components__src-components-Margin-__Margin__left--small {
      margin-left: 0.36em
} .quid-react-components__src-components-Margin-__Margin__left--regular {
      margin-left: 0.71em
} .quid-react-components__src-components-Margin-__Margin__left--large {
      margin-left: 1.43em
}

.quid-react-components__src-components-Markdown-__Markdown {}
  .quid-react-components__src-components-Markdown-__Markdown__ul, .quid-react-components__src-components-Markdown-__Markdown__ol {
    margin-top: 0.93em;
    margin-bottom: 0.93em;
    padding-left: 1.86em;
    text-indent: -1.30em
}
  .quid-react-components__src-components-Markdown-__Markdown__ul .quid-react-components__src-components-Markdown-__Markdown__ul, .quid-react-components__src-components-Markdown-__Markdown__ul .quid-react-components__src-components-Markdown-__Markdown__ol, .quid-react-components__src-components-Markdown-__Markdown__ol .quid-react-components__src-components-Markdown-__Markdown__ul, .quid-react-components__src-components-Markdown-__Markdown__ol .quid-react-components__src-components-Markdown-__Markdown__ol, .quid-react-components__src-components-Markdown-__Markdown {
    margin-top: 0;
    margin-bottom: 0
}
  .quid-react-components__src-components-Markdown-__Markdown__ul {
    list-style-type: disc;
    list-style-position: inside
}
  .quid-react-components__src-components-Markdown-__Markdown__ol {
    list-style-type: decimal;
    list-style-position: inside
}
  .quid-react-components__src-components-Markdown-__Markdown__ul .quid-react-components__src-components-Markdown-__Markdown__ul, .quid-react-components__src-components-Markdown-__Markdown__ol .quid-react-components__src-components-Markdown-__Markdown__ul {
    list-style-type: circle;
    list-style-position: inside
}
  .quid-react-components__src-components-Markdown-__Markdown__ol .quid-react-components__src-components-Markdown-__Markdown__ol, .quid-react-components__src-components-Markdown-__Markdown__ul .quid-react-components__src-components-Markdown-__Markdown__ol {
    list-style-type: lower-latin;
    list-style-position: inside
}

.quid-react-components__src-components-Modal-__Modal__centerer, .quid-react-components__src-components-Modal-__Header__title {
  font-family: IBM Plex Sans,Lucida Grande,Tahoma,Verdana,Arial,sans-serif;
  color: var(--primary);
  font-size: 14px;
  line-height: 1.57;
  font-weight: normal
}
.quid-react-components__src-components-Modal-__Header__title {
  font-family: IBM Plex Sans,Lucida Grande,Tahoma,Verdana,Arial,sans-serif;
  font-size: 20px;
  line-height: 1.4
}
.quid-react-components__src-components-Modal-__Modal, [data-theme="light"] .quid-react-components__src-components-Modal-__Modal, [data-theme] [data-theme="light"] .quid-react-components__src-components-Modal-__Modal, [data-theme="light"].quid-react-components__src-components-Modal-__Modal {}
.quid-react-components__src-components-Modal-__Modal__overlay, .quid-react-components__src-components-Modal-__Modal__content, [data-theme="light"] .quid-react-components__src-components-Modal-__Modal__overlay, [data-theme="light"] .quid-react-components__src-components-Modal-__Modal__content, [data-theme] [data-theme="light"] .quid-react-components__src-components-Modal-__Modal__overlay, [data-theme] [data-theme="light"] .quid-react-components__src-components-Modal-__Modal__content, [data-theme="light"].quid-react-components__src-components-Modal-__Modal__overlay, [data-theme="light"].quid-react-components__src-components-Modal-__Modal__content {
    --modalBackground: #FFFFFF;
    --modalHeader: var(--white);
    --modalHeaderSecondary: var(--gray1)
}
[data-theme="dark"] .quid-react-components__src-components-Modal-__Modal, [data-theme] [data-theme="dark"] .quid-react-components__src-components-Modal-__Modal, [data-theme="dark"].quid-react-components__src-components-Modal-__Modal,  {}
[data-theme="dark"] .quid-react-components__src-components-Modal-__Modal__overlay, [data-theme="dark"] .quid-react-components__src-components-Modal-__Modal__content, [data-theme] [data-theme="dark"] .quid-react-components__src-components-Modal-__Modal__overlay, [data-theme] [data-theme="dark"] .quid-react-components__src-components-Modal-__Modal__content, [data-theme="dark"].quid-react-components__src-components-Modal-__Modal__overlay, [data-theme="dark"].quid-react-components__src-components-Modal-__Modal__content, __overlay, __content {
    --modalBackground: #000000;
    --modalHeader: var(--black);
    --modalHeaderSecondary: var(--gray7)
}
.quid-react-components__src-components-Modal-__Modal {}
.quid-react-components__src-components-Modal-__Modal__overlay {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(18, 18, 18, 0.6)
}
.quid-react-components__src-components-Modal-__Modal__content {
    font-size: 1rem;
    width: 57.11em;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    color: rgba(18, 18, 18, 1);
    color: var(--primary);
    background-color: #FFFFFF;
    background-color: var(--modalBackground);
    -webkit-box-shadow: 0.14em 0.14em 0.14em rgba(18, 18, 18, 0.1);
            box-shadow: 0.14em 0.14em 0.14em rgba(18, 18, 18, 0.1);
    border-radius: 2px;
    border: 1px solid;
    border-color: rgba(227, 230, 232, 1);
    border-color: var(--modalHeaderSecondary)
}
.quid-react-components__src-components-Modal-__Modal__content--standardSized {
      min-height: 25.00em;
      max-height: 35.71em
}
[data-whatinput="initial"] .quid-react-components__src-components-Modal-__Modal__content:focus, [data-whatinput="mouse"] .quid-react-components__src-components-Modal-__Modal__content:focus {
      outline: 0
}
.quid-react-components__src-components-Modal-__Modal__centerer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    overflow-y: auto
}
.quid-react-components__src-components-Modal-__Modal__centerer--padded {
      padding: 2.04em 7.06em
}
.quid-react-components__src-components-Modal-__Modal__centerer--centerVertically {
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center
}
.quid-react-components__src-components-Modal-__Modal__main {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1
}
.quid-react-components__src-components-Modal-__Modal__action {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    height: 3.57em; /* 50px */
    padding: 0.71em;
    border-top: 1px solid;
    border-color: rgba(227, 230, 232, 1);
    border-color: var(--modalHeaderSecondary)
}
.quid-react-components__src-components-Modal-__Modal__side {}
.quid-react-components__src-components-Modal-__Modal__side--left {
      margin-right: auto
}
.quid-react-components__src-components-Modal-__Modal__side--right {
      margin-left: auto
}
.quid-react-components__src-components-Modal-__Modal__button {
    margin: 0 calc(0.71em / 2)
}
.quid-react-components__src-components-Modal-__Modal__button--first {
      margin-left: 0
}
.quid-react-components__src-components-Modal-__Modal__button--last {
      margin-right: 0
}
/* CSS Hack to target only IE10-11 */
/* This "fixes" the element height in some circumstances */
_:-ms-input-placeholder, :root .quid-react-components__src-components-Modal-__Modal__main {
  min-height: inherit
}
.quid-react-components__src-components-Modal-__Header {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 4.64em;
  border-bottom: 1px solid transparent;
  border-radius: 2px 2px 0 0
}
.quid-react-components__src-components-Modal-__Header--action {
    color: rgba(18, 18, 18, 1);
    color: var(--black);
    background-color: rgba(255, 255, 255, 1);
    background-color: var(--modalHeader);
    border-color: rgba(227, 230, 232, 1);
    border-color: var(--modalHeaderSecondary)
}
.quid-react-components__src-components-Modal-__Header--info {
    color: rgba(18, 18, 18, 1);
    color: var(--black);
    background-color: #B5ECEA;
    border-color: #B5ECEA
}
.quid-react-components__src-components-Modal-__Header--warning {
    background-color: #F5BBBE;
    border-color: #F5BBBE
}
.quid-react-components__src-components-Modal-__Header__title {
    color: inherit
}
.quid-react-components__src-components-Modal-__Header__icon {
    color: rgba(18, 18, 18, 1);
    color: var(--primary);
    -webkit-box-sizing: initial;
            box-sizing: initial;
    text-align: center;
    font-size: 2.60em;
    height: 0.93em;
    width: 0.93em;
    display: block;
    position: absolute;
    bottom: 0;
    left: 0.56em;
    -webkit-transform: translateY(30%);
        -ms-transform: translateY(30%);
            transform: translateY(30%);
    padding: 0.37em;
    background-color: rgba(227, 230, 232, 1);
    background-color: var(--modalHeaderSecondary);
    border-radius: 100%;
    -webkit-box-shadow: 0 0 0 2px #FFFFFF;
            box-shadow: 0 0 0 2px #FFFFFF;
    -webkit-box-shadow: 0 0 0 2px var(--modalBackground);
            box-shadow: 0 0 0 2px var(--modalBackground)
}

.quid-react-components__src-components-Navbar-__Navbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  height: 50px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: var(--primary)
}

.quid-react-components__src-components-Navbar-__Navbar__logo {
    /* extra padding needed to vertical align logo */
    padding-top: calc(0.19em + 0.70em);
    padding-left: 0.70em;
    padding-bottom: 0.70em;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    min-width: 0;
    height: 100%
}

.quid-react-components__src-components-Navbar-__Navbar__breadcrumb {
    padding-left: calc(0.70em * 3)
}

.quid-react-components__src-components-Navbar-__Navbar__tools {
    margin-left: auto;
    padding-right: 0.70em
}

.quid-react-components__src-components-Panel-__Panel, [data-theme="light"] .quid-react-components__src-components-Panel-__Panel, [data-theme] [data-theme="light"] .quid-react-components__src-components-Panel-__Panel, [data-theme="light"].quid-react-components__src-components-Panel-__Panel {
  --backgroundColor: var(--gray1)
}

[data-theme="dark"] .quid-react-components__src-components-Panel-__Panel, [data-theme] [data-theme="dark"] .quid-react-components__src-components-Panel-__Panel, [data-theme="dark"].quid-react-components__src-components-Panel-__Panel {
  --backgroundColor: var(--gray7)
}

.quid-react-components__src-components-Panel-__Panel {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 270px;
  color: var(--primary);
  /* The most common use case for Panels is to be stacked */
  /* inside a flexbox column. With this we assure they */
  /* will shrink if the column isn't high enough. */
  min-height: 0;
  -webkit-transition: width .2s ease-in-out;
  -o-transition: width .2s ease-in-out;
  transition: width .2s ease-in-out
}

.quid-react-components__src-components-Panel-__Panel--collapsed {
    width: 2.28em;
    -webkit-transition-delay: .2s;
         -o-transition-delay: .2s;
            transition-delay: .2s
}

.quid-react-components__src-components-Panel-__Panel__title, .quid-react-components__src-components-Panel-__Panel__toggle, .quid-react-components__src-components-Panel-__Panel__content {
    border-radius: 2px;
    -webkit-box-shadow: 0 0 2px rgba(0, 0, 0, .6);
            box-shadow: 0 0 2px rgba(0, 0, 0, .6);
    background-color: var(--backgroundColor)
}

.quid-react-components__src-components-Panel-__Panel__content {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    min-width: 0;
    min-height: 0
}

.quid-react-components__src-components-Panel-__Panel__header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 2.28em;
    margin-bottom: 3px;
    -ms-flex-negative: 0;
        flex-shrink: 0
}

.quid-react-components__src-components-Panel-__Panel__header--rightAlign {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: reverse;
          -ms-flex-direction: row-reverse;
              flex-direction: row-reverse
}

.quid-react-components__src-components-Panel-__Panel__header--noMarginBottom {
      margin-bottom: 0
}

.quid-react-components__src-components-Panel-__Panel__toggle {
    -ms-flex-negative: 0;
        flex-shrink: 0;
    margin-right: 3px
}

.quid-react-components__src-components-Panel-__Panel__header--rightAlign .quid-react-components__src-components-Panel-__Panel__toggle {
    margin-right: 0;
    margin-left: 3px
}

.quid-react-components__src-components-Panel-__Panel__title {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    min-width: 0
}

.quid-react-components__src-components-Panel-__Panel__titleAnimator, .quid-react-components__src-components-Panel-__Panel__contentAnimator {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    min-height: 0;
    min-width: 0
}

.quid-react-components__src-components-Panel-__Panel__title, .quid-react-components__src-components-Panel-__Panel__content {
    -webkit-transition: opacity .2s ease-in;
    -o-transition: opacity .2s ease-in;
    transition: opacity .2s ease-in
}

.quid-react-components__src-components-Panel-__Panel__title--entering, .quid-react-components__src-components-Panel-__Panel__content--entering {
      opacity: 0
}

.quid-react-components__src-components-Panel-__Panel__title--entered, .quid-react-components__src-components-Panel-__Panel__content--entered {
      opacity: 1
}

.quid-react-components__src-components-Panel-__Panel__title--exiting, .quid-react-components__src-components-Panel-__Panel__content--exiting {
      opacity: 1
}

.quid-react-components__src-components-Panel-__Panel__title--exited, .quid-react-components__src-components-Panel-__Panel__content--exited {
      opacity: 0
}

.quid-react-components__src-components-Panel-__Panel__content--exited {
    /*display: none */
}

.quid-react-components__src-components-Panel-Title-__PanelTitle {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center
}

  .quid-react-components__src-components-Panel-Title-__PanelTitle--padded {
    padding-left: 0.46em;
    padding-right: 0.46em
}

  .quid-react-components__src-components-Panel-Title-__PanelTitle__trimmer {
    overflow: hidden;
    -o-text-overflow: ellipsis;
       text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: bold
}

.quid-react-components__src-components-Panel-Toggle-__PanelToggle {}

  .quid-react-components__src-components-Panel-Toggle-__PanelToggle__button {
    width: 2.28em;
    height: 2.28em
}

  .quid-react-components__src-components-Panel-Toggle-__PanelToggle__icon {
    font-size: 1.58em
}

.quid-react-components__src-components-PillList-__PillList {}

  .quid-react-components__src-components-PillList-__PillList__header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding-left: 0.46em
}

  .quid-react-components__src-components-PillList-__PillList__addButton {
    margin-left: auto;
    font-size: 0.93em;
    margin-right: 1px
}

  .quid-react-components__src-components-PillList-__PillList__addIcon {
    font-size: 1.39em
}

  .quid-react-components__src-components-PillList-__PillList__noPills {
    padding: 0.46em;
    color: var(--secondary)
}

  .quid-react-components__src-components-PillList-__PillList__pills {
    all: unset;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    padding: 0.35em
}

  .quid-react-components__src-components-PillList-__PillList__pill {
    margin: 0.12em
}

.quid-react-components__src-components-PillList-Pill-__Pill {
  font-family: IBM Plex Sans,Lucida Grande,Tahoma,Verdana,Arial,sans-serif;
  color: var(--primary);
  font-size: 14px;
  line-height: 1.57;
  font-weight: normal
}
.quid-react-components__src-components-PillList-Pill-__Pill--disabled, .quid-react-components__src-components-PillList-Pill-__Pill--disabled .quid-react-components__src-components-PillList-Pill-__Pill__counter {
  color: var(--disabled)
}
.quid-react-components__src-components-PillList-Pill-__Pill {
  background-color: var(--primaryInverse);
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 2px;
  border: 1px solid var(--primaryInverse);
  -webkit-transition: border 0.2s ease-in-out;
  -o-transition: border 0.2s ease-in-out;
  transition: border 0.2s ease-in-out;
  padding: 0.19em 0.46em
}
.quid-react-components__src-components-PillList-Pill-__Pill--highlighted {
    border-color: var(--selected)
}
.quid-react-components__src-components-PillList-Pill-__Pill__input {
    /* Reset */
    all: unset;

    /* Style */
    min-width: 1.95em;
    letter-spacing: 0.05em;
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
       text-overflow: ellipsis
}
[data-whatinput="initial"] .quid-react-components__src-components-PillList-Pill-__Pill__input:focus, [data-whatinput="mouse"] .quid-react-components__src-components-PillList-Pill-__Pill__input:focus {
      outline: none
}
.quid-react-components__src-components-PillList-Pill-__Pill__input--button {
      /* Reset */
      cursor: pointer;
      background-color: transparent;
      font: inherit;
      border: none;
      color: inherit;
      padding: 0;

      /* Style */
      margin-left: -0.19em;
      padding-top: 0.14em;
      padding-bottom: 0.14em
}
.quid-react-components__src-components-PillList-Pill-__Pill__input--button:disabled {
        cursor: default
}
/* This is for Chrome to avoid little unwanted offsets */
@media all and (-webkit-min-device-pixel-ratio:0) and (min-resolution: .001dpcm) {
.quid-react-components__src-components-PillList-Pill-__Pill__input--button {
        margin-left: 0
  }
}
.quid-react-components__src-components-PillList-Pill-__Pill__counter {
    color: var(--secondary);
    padding-left: 0.46em
}
.quid-react-components__src-components-PillList-Pill-__Pill__delete {
    /* Reset */
    cursor: pointer;
    background-color: transparent;
    font: inherit;
    border: none;
    color: inherit;
    padding-top: 0;
    padding-bottom: 0;
    color: var(--primary)
}
[data-whatinput="initial"] .quid-react-components__src-components-PillList-Pill-__Pill__delete:focus, [data-whatinput="mouse"] .quid-react-components__src-components-PillList-Pill-__Pill__delete:focus {
      outline: none
}

.quid-react-components__src-components-QuadrantTab-__QuadrantTab {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 1px
}

  .quid-react-components__src-components-QuadrantTab-__QuadrantTab__header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    margin-bottom: 0.28em
}

  .quid-react-components__src-components-QuadrantTab-__QuadrantTab__main {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    min-height: 0;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1
}

  .quid-react-components__src-components-QuadrantTab-__QuadrantTab__column {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    min-width: 0
}

  .quid-react-components__src-components-QuadrantTab-__QuadrantTab__column:empty {
      display: none
}

  .quid-react-components__src-components-QuadrantTab-__QuadrantTab__button {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    min-width: 0;
    border-radius: 0;
    border-right-width: 0
}

  .quid-react-components__src-components-QuadrantTab-__QuadrantTab__button:first-of-type {
      border-radius: 2px 0 0 2px
}

  .quid-react-components__src-components-QuadrantTab-__QuadrantTab__button:last-of-type {
      border-radius: 0 2px 2px 0;
      border-right-width: 1px
}

.quid-react-components__src-components-QuadrantTab-Quadrant-__Quadrant {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-negative: 1;
      flex-shrink: 1;
  padding: 1px;
  min-height: 0
}

  .quid-react-components__src-components-QuadrantTab-Quadrant-__Quadrant:only-child {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1
}

  .quid-react-components__src-components-QuadrantTab-Quadrant-__Quadrant--noShrink {
    -ms-flex-negative: 0;
        flex-shrink: 0;
    max-height: 50%
}

  .quid-react-components__src-components-QuadrantTab-Quadrant-__Quadrant--noShrink:only-child {
      max-height: 100%
}

.quid-react-components__src-components-RadialGaugeChart-__RadialGaugeChart {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  position: relative
}

  .quid-react-components__src-components-RadialGaugeChart-__RadialGaugeChart__chart {
    -webkit-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
            transform: rotate(-90deg)
}

  .quid-react-components__src-components-RadialGaugeChart-__RadialGaugeChart__labelBox {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    position: absolute;
    top: 12%;
    left: 12%;
    right: 12%;
    bottom: 12%;
    margin: 0.14em
}

  .quid-react-components__src-components-RadialGaugeChart-__RadialGaugeChart__label {
    font-size: 0.23em;
    color: var(--primary)
}

.quid-react-components__src-components-SegmentBarChart-__SegmentBarChart {
  height: 0.35em;
  border-radius: 2px;
  margin-top: 0.35em;
  margin-bottom: 0.35em
}

.quid-react-components__src-components-Snackbar-__Snackbar__bar {
  font-family: IBM Plex Sans,Lucida Grande,Tahoma,Verdana,Arial,sans-serif;
  color: var(--primary);
  font-size: 14px;
  line-height: 1.57;
  font-weight: normal
}
.quid-react-components__src-components-Snackbar-__Snackbar__bar--warning {
  font-weight: bold
}
/* http://www.rapidtables.com/web/dev/screen-resolution-statistics.htm */
.quid-react-components__src-components-Snackbar-__Snackbar, [data-theme="light"] .quid-react-components__src-components-Snackbar-__Snackbar, [data-theme] [data-theme="light"] .quid-react-components__src-components-Snackbar-__Snackbar, [data-theme="light"].quid-react-components__src-components-Snackbar-__Snackbar {
  --snackbarBackground: var(--white)
}
[data-theme="dark"] .quid-react-components__src-components-Snackbar-__Snackbar, [data-theme] [data-theme="dark"] .quid-react-components__src-components-Snackbar-__Snackbar, [data-theme="dark"].quid-react-components__src-components-Snackbar-__Snackbar {
  --snackbarBackground: var(--gray5)
}
.quid-react-components__src-components-Snackbar-__Snackbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-left: 0.93em;
  padding-right: 0.93em;
  z-index: 1;
  will-change: height, transform;
  -webkit-transition:
    height .3s ease-in-out,
    opacity .1s ease-in-out .1s,
    -webkit-transform .2s ease-in-out;
  transition:
    height .3s ease-in-out,
    opacity .1s ease-in-out .1s,
    -webkit-transform .2s ease-in-out;
  -o-transition:
    transform .2s ease-in-out,
    height .3s ease-in-out,
    opacity .1s ease-in-out .1s;
  transition:
    transform .2s ease-in-out,
    height .3s ease-in-out,
    opacity .1s ease-in-out .1s;
  transition:
    transform .2s ease-in-out,
    height .3s ease-in-out,
    opacity .1s ease-in-out .1s,
    -webkit-transform .2s ease-in-out
}
.quid-react-components__src-components-Snackbar-__Snackbar--stacked {
    position: relative
}
.quid-react-components__src-components-Snackbar-__Snackbar--entered {
    height: 3.57em;
    -webkit-transform: translate(0%);
        -ms-transform: translate(0%);
            transform: translate(0%);
    opacity: 1
}
.quid-react-components__src-components-Snackbar-__Snackbar--entering {
    -webkit-transform: translateY(calc(100% + .5em));
        -ms-transform: translateY(calc(100% + .5em));
            transform: translateY(calc(100% + .5em));
    height: 0;
    opacity: 0
}
.quid-react-components__src-components-Snackbar-__Snackbar--exiting {
    -webkit-transform: translateY(0%);
        -ms-transform: translateY(0%);
            transform: translateY(0%);
    height: 0;
    opacity: 0;
    z-index: 0
}
.quid-react-components__src-components-Snackbar-__Snackbar--exited {
    -webkit-transform: translateY(calc(100% + .5em));
        -ms-transform: translateY(calc(100% + .5em));
            transform: translateY(calc(100% + .5em));
    display: none
}
.quid-react-components__src-components-Snackbar-__Snackbar__bar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: absolute;
    top: 0;
    right: auto;
    bottom: 0;
    left: auto;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-left: auto;
    margin-right: auto;
    max-width: calc(1366px - 2em);
    height: 3.57em; /* 50px */
    -webkit-box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
            box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    padding-left: 1.42em; /* 20px */
    padding-right: 1.42em; /* 20px */
    border-radius: 2px
}
.quid-react-components__src-components-Snackbar-__Snackbar__bar--action {
      background-color: var(--snackbarBackground)
}
.quid-react-components__src-components-Snackbar-__Snackbar__bar--warning {
      background-color: var(--red);
      color: var(--white)
}
.quid-react-components__src-components-Snackbar-__Snackbar__text {
    padding-right: 1.42em; /* 20px */
    overflow: hidden;
    white-space: nowrap;
    -o-text-overflow: ellipsis;
       text-overflow: ellipsis
}
.quid-react-components__src-components-Snackbar-__Snackbar__action {
    margin-left: auto
}

.quid-react-components__src-components-SnackbarStack-__SnackbarStack {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column
}

.quid-react-components__src-components-Spinner-__Spinner {
  display: inline-block
}

.quid-react-components__src-components-Tabs-__Tabs__tab {
  font-family: IBM Plex Sans,Lucida Grande,Tahoma,Verdana,Arial,sans-serif;
  color: var(--primary);
  font-size: 14px;
  line-height: 1.57;
  font-weight: normal
}
.quid-react-components__src-components-Tabs-__Tabs__tab {
  font-weight: bold
}
.quid-react-components__src-components-Tabs-__Tabs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column
}
.quid-react-components__src-components-Tabs-__Tabs__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-bottom: 1px solid var(--primaryInverse);
    -ms-flex-negative: 0;
        flex-shrink: 0
}
.quid-react-components__src-components-Tabs-__Tabs__listItem {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}
.quid-react-components__src-components-Tabs-__Tabs__tab {
    /* Reset */
    border: 0;
    font-size: inherit;
    font-family: inherit;
    cursor: pointer;
    background: inherit;

    /* Style */
    position: relative;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    padding: 0.74em;
    line-height: 1
}
.quid-react-components__src-components-Tabs-__Tabs__tab--active {
      background-color: var(--primaryInverse);
      color: var(--selected)
}
[data-whatinput="initial"] .quid-react-components__src-components-Tabs-__Tabs__tab:focus, [data-whatinput="mouse"] .quid-react-components__src-components-Tabs-__Tabs__tab:focus {
      outline: 0
}
[data-whatinput="keyboard"] .quid-react-components__src-components-Tabs-__Tabs__tab:focus {
      z-index: 1
}
.quid-react-components__src-components-Tabs-__Tabs__panels {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -webkit-transition: height .2s ease-in-out;
    -o-transition: height .2s ease-in-out;
    transition: height .2s ease-in-out;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    overflow: auto
}
.quid-react-components__src-components-Tabs-__Tabs__panel {
    padding: 0.93em;
    will-change: opacity, position
}
.quid-react-components__src-components-Tabs-__Tabs__panel--leave {
      opacity: 1;
      -webkit-transition: opacity .2s ease-in-out;
      -o-transition: opacity .2s ease-in-out;
      transition: opacity .2s ease-in-out
}
.quid-react-components__src-components-Tabs-__Tabs__panel--leaveActive {
      opacity: 0
}
.quid-react-components__src-components-Tabs-__Tabs__panel--enter {
      opacity: 0;
      -webkit-transition: opacity .2s ease-in-out;
      -o-transition: opacity .2s ease-in-out;
      transition: opacity .2s ease-in-out
}
.quid-react-components__src-components-Tabs-__Tabs__panel--enterActive {
      opacity: 1
}

.quid-react-components__src-components-TextArea-__TextArea {
  border: 1px solid var(--gray2);
  border-radius: 2px;
  padding: 0.44em 0.71em;
  min-height: 2.14em;
  -webkit-transition: border .2s ease-in-out;
  -o-transition: border .2s ease-in-out;
  transition: border .2s ease-in-out;
  background-color: var(--white);
  color: var(--black)
}

  .quid-react-components__src-components-TextArea-__TextArea:focus {
    outline: 0;
    border-color: var(--selected)
}

  .quid-react-components__src-components-TextArea-__TextArea--invalid {
    border-color: var(--red)
}

  .quid-react-components__src-components-TextArea-__TextArea:disabled {
    background-color: var(--gray1);
    color: var(--gray3)
}

.quid-react-components__src-components-Thumbnail-Action-__Action {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  --padding: 0.71em; /* 10px */
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  height: 100%
}

  .quid-react-components__src-components-Thumbnail-Action-__Action--small {
    --padding: calc(0.71em / 2)
}

  .quid-react-components__src-components-Thumbnail-Action-__Action__center {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1
}

  .quid-react-components__src-components-Thumbnail-Action-__Action__bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: var(--padding)
}

  .quid-react-components__src-components-Thumbnail-Action-__Action__left {
    margin-right: auto
}

  .quid-react-components__src-components-Thumbnail-Action-__Action__right {
    margin-left: auto
}

.quid-react-components__src-components-Thumbnail-Skeleton-__Skeleton {
  --skeletonBackground: var(--gray1);
  --skeletonPrimary: var(--gray3);
  --skeletonSecondary: var(--gray2);

  background-color: var(--skeletonBackground);
  border-radius: 2px;
  width: 270px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column
}

  .quid-react-components__src-components-Thumbnail-Skeleton-__Skeleton--small {
    width: 170px
}

  .quid-react-components__src-components-Thumbnail-Skeleton-__Skeleton__action {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    height: 150px;
    background-color: var(--skeletonSecondary);
    border-radius: 2px 2px 0 0
}

  .quid-react-components__src-components-Thumbnail-Skeleton-__Skeleton__action--small {
      height: 95px
}

  .quid-react-components__src-components-Thumbnail-Skeleton-__Skeleton__title {
    margin: 0.74em 0.93em
}

.quid-react-components__src-components-Thumbnail-__Thumbnail__titleText, .quid-react-components__src-components-Thumbnail-__Thumbnail__date {
  font-family: IBM Plex Sans,Lucida Grande,Tahoma,Verdana,Arial,sans-serif;
  color: var(--primary);
  font-size: 14px;
  line-height: 1.57;
  font-weight: normal
}
.quid-react-components__src-components-Thumbnail-__Thumbnail__date {
  color: var(--secondary)
}
.quid-react-components__src-components-Thumbnail-__Thumbnail {
  width: 270px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background-color: var(--white);
  border-radius: 2px;
  -webkit-box-shadow: 0 2px 2px 0 rgba(18, 18, 18, 0.2);
          box-shadow: 0 2px 2px 0 rgba(18, 18, 18, 0.2);
  -webkit-transition: .2s box-shadow ease-in-out;
  -o-transition: .2s box-shadow ease-in-out;
  transition: .2s box-shadow ease-in-out
}
.quid-react-components__src-components-Thumbnail-__Thumbnail:hover {
    -webkit-box-shadow: 0 3px 6px 0 rgba(18, 18, 18, 0.2),
                0 3px 6px 0 rgba(18, 18, 18, 0.16);
            box-shadow: 0 3px 6px 0 rgba(18, 18, 18, 0.2),
                0 3px 6px 0 rgba(18, 18, 18, 0.16)
}
.quid-react-components__src-components-Thumbnail-__Thumbnail--dragging {}
.quid-react-components__src-components-Thumbnail-__Thumbnail--dragging, .quid-react-components__src-components-Thumbnail-__Thumbnail--dragging:hover {
      -webkit-box-shadow: 0  6px  6px 0 rgba(18, 18, 18, 0.26),
                  0 10px 20px 0 rgba(18, 18, 18, 0.19);
              box-shadow: 0  6px  6px 0 rgba(18, 18, 18, 0.26),
                  0 10px 20px 0 rgba(18, 18, 18, 0.19)
}
.quid-react-components__src-components-Thumbnail-__Thumbnail--disabled {}
.quid-react-components__src-components-Thumbnail-__Thumbnail--disabled, .quid-react-components__src-components-Thumbnail-__Thumbnail--disabled:hover {
      -webkit-box-shadow: none;
              box-shadow: none
}
.quid-react-components__src-components-Thumbnail-__Thumbnail--disabled .quid-react-components__src-components-Thumbnail-__Thumbnail__image {
      opacity: .5
}
.quid-react-components__src-components-Thumbnail-__Thumbnail--disabled .quid-react-components__src-components-Thumbnail-__Thumbnail__title, .quid-react-components__src-components-Thumbnail-__Thumbnail--disabled .quid-react-components__src-components-Thumbnail-__Thumbnail__date {
      color: var(--gray3)
}
.quid-react-components__src-components-Thumbnail-__Thumbnail--disabled .quid-react-components__src-components-Thumbnail-__Thumbnail__action {
      background-color: rgba(255, 255, 255, 0.2)
}
.quid-react-components__src-components-Thumbnail-__Thumbnail--small {
    width: 170px
}
.quid-react-components__src-components-Thumbnail-__Thumbnail__image {
    border-radius: 2px 2px 0 0;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column
}
.quid-react-components__src-components-Thumbnail-__Thumbnail__image--fullRounded {
      border-radius: 2px
}
.quid-react-components__src-components-Thumbnail-__Thumbnail__caption {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    padding: 0.71em;
    border: 1px solid var(--gray1);
    border-top-width: 0;
    border-radius: 0 0 2px 2px
}
.quid-react-components__src-components-Thumbnail-__Thumbnail__title {
    text-align: center
}
.quid-react-components__src-components-Thumbnail-__Thumbnail__titleText, .quid-react-components__src-components-Thumbnail-__Thumbnail__date {
    text-align: center;
    overflow: hidden;
    -o-text-overflow: ellipsis;
       text-overflow: ellipsis;
    white-space: nowrap
}
.quid-react-components__src-components-Thumbnail-__Thumbnail__titleText--wrap, .quid-react-components__src-components-Thumbnail-__Thumbnail__date--wrap {
      white-space: initial
}
.quid-react-components__src-components-Thumbnail-__Thumbnail__titleText {
    color: rgba(18, 18, 18, 1)
}
.quid-react-components__src-components-Thumbnail-__Thumbnail__date {
    color: rgba(109, 121, 131, 1);
    margin-top: 0.35em
}
.quid-react-components__src-components-Thumbnail-__Thumbnail__top {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column
}
[data-whatinput="initial"] .quid-react-components__src-components-Thumbnail-__Thumbnail__top:focus, [data-whatinput="mouse"] .quid-react-components__src-components-Thumbnail-__Thumbnail__top:focus {
      outline: 0
}
.quid-react-components__src-components-Thumbnail-__Thumbnail__action {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    background-color: rgba(18, 18, 18, 0.6);
    border-radius: 2px 2px 0 0;
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out
}
.quid-react-components__src-components-Thumbnail-__Thumbnail__action--fullRounded {
      border-radius: 2px
}
.quid-react-components__src-components-Thumbnail-__Thumbnail__action--visible {
      opacity: 1
}
.quid-react-components__src-components-Thumbnail-__Thumbnail__separator {
    margin-top: 0.71em;
    margin-bottom: 0.71em;
    margin-left: -0.71em;
    margin-right: -0.71em;
    width: calc(100% + (0.71em * 2))
}

.quid-react-components__src-components-TimelineChart-__TimelineChart {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column
}

  .quid-react-components__src-components-TimelineChart-__TimelineChart__chart {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1
}

  .quid-react-components__src-components-TimelineChart-__TimelineChart__labels {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding-top: 0.37em;
    padding-bottom: 0.37em
}

.quid-react-components__src-components-TimelineChart-Bar-__Bar {
  min-width: 0;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  margin-right: 1px;
  color: var(--gray6);
  height: 100%
}

  .quid-react-components__src-components-TimelineChart-Bar-__Bar:last-child {
    margin-right: 0
}

.quid-react-components__src-components-ToggleableSection-__ToggleableSection__content {
  font-family: IBM Plex Sans,Lucida Grande,Tahoma,Verdana,Arial,sans-serif;
  color: var(--primary);
  font-size: 14px;
  line-height: 1.57;
  font-weight: normal
}
.quid-react-components__src-components-ToggleableSection-__ToggleableSection {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column
}
.quid-react-components__src-components-ToggleableSection-__ToggleableSection__header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding-left: 0.71em;
    padding-top: 0.35em;
    padding-bottom: 0.35em;
    border-bottom: 1px solid var(--gray2);
    -ms-flex-negative: 0;
        flex-shrink: 0;
    min-height: 2.46em
}
.quid-react-components__src-components-ToggleableSection-__ToggleableSection__header--clickable {
      cursor: pointer
}
[data-whatinput="initial"] .quid-react-components__src-components-ToggleableSection-__ToggleableSection__header--clickable:focus, [data-whatinput="mouse"] .quid-react-components__src-components-ToggleableSection-__ToggleableSection__header--clickable:focus {
        outline: 0
}
.quid-react-components__src-components-ToggleableSection-__ToggleableSection__toggle {
    margin-left: auto;
    margin-right: 0.71em
}
.quid-react-components__src-components-ToggleableSection-__ToggleableSection__icon {
    -webkit-transition: -webkit-transform .2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transition: -webkit-transform .2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -o-transition: transform .2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transition: transform .2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transition: transform .2s cubic-bezier(0.175, 0.885, 0.32, 1.275), -webkit-transform .2s cubic-bezier(0.175, 0.885, 0.32, 1.275)
}
.quid-react-components__src-components-ToggleableSection-__ToggleableSection__icon--open {
      -webkit-transform: rotate(90deg);
          -ms-transform: rotate(90deg);
              transform: rotate(90deg)
}
.quid-react-components__src-components-ToggleableSection-__ToggleableSection__animateHeight {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    min-height: 0
}
.quid-react-components__src-components-ToggleableSection-__ToggleableSection__content {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    min-height: 0;
    padding: 0.46em;
    overflow: auto
}

:root, [data-theme="light"], [data-theme] [data-theme="light"] {}
  :root .quid-react-components__src-components-Typeahead-__Typeahead, :root.quid-react-components__src-components-Typeahead-__Typeahead, :root .quid-react-components__src-components-Typeahead-__Typeahead__dropdown, :root.quid-react-components__src-components-Typeahead-__Typeahead__dropdown, [data-theme="light"] .quid-react-components__src-components-Typeahead-__Typeahead, [data-theme="light"].quid-react-components__src-components-Typeahead-__Typeahead, [data-theme="light"] .quid-react-components__src-components-Typeahead-__Typeahead__dropdown, [data-theme="light"].quid-react-components__src-components-Typeahead-__Typeahead__dropdown, [data-theme] [data-theme="light"] .quid-react-components__src-components-Typeahead-__Typeahead, [data-theme] [data-theme="light"].quid-react-components__src-components-Typeahead-__Typeahead, [data-theme] [data-theme="light"] .quid-react-components__src-components-Typeahead-__Typeahead__dropdown, [data-theme] [data-theme="light"].quid-react-components__src-components-Typeahead-__Typeahead__dropdown {
    --highlightBackground: var(--gray2)
}
  [data-theme="dark"], [data-theme] [data-theme="dark"] {}
  [data-theme="dark"] .quid-react-components__src-components-Typeahead-__Typeahead, [data-theme="dark"].quid-react-components__src-components-Typeahead-__Typeahead, [data-theme="dark"] .quid-react-components__src-components-Typeahead-__Typeahead__dropdown, [data-theme="dark"].quid-react-components__src-components-Typeahead-__Typeahead__dropdown, [data-theme] [data-theme="dark"] .quid-react-components__src-components-Typeahead-__Typeahead, [data-theme] [data-theme="dark"].quid-react-components__src-components-Typeahead-__Typeahead, [data-theme] [data-theme="dark"] .quid-react-components__src-components-Typeahead-__Typeahead__dropdown, [data-theme] [data-theme="dark"].quid-react-components__src-components-Typeahead-__Typeahead__dropdown {
    --highlightBackground: var(--gray5)
}
  .quid-react-components__src-components-Typeahead-__Typeahead {
  position: relative;
  display: inline-block
}
  .quid-react-components__src-components-Typeahead-__Typeahead__input {
    /* Reset */
    border: 0;
    margin: 0;
    padding: 0;
    font: inherit;

    /* Style */
    background-color: var(--primaryInverse);
    color: var(--primary);
    border: 1px solid var(--primaryInverse);
    padding-left: 0.56em;
    padding-top: 0.65em;
    padding-bottom: 0.56em;
    padding-right: 2.32em;
    border-radius: 2px;
    cursor: pointer
}
  [data-whatinput="initial"] .quid-react-components__src-components-Typeahead-__Typeahead__input:focus, [data-whatinput="mouse"] .quid-react-components__src-components-Typeahead-__Typeahead__input:focus {
      outline: 0
}
  .quid-react-components__src-components-Typeahead-__Typeahead__input--focus {
      background-color: var(--primary);
      color: var(--primaryInverse);
      cursor: text
}
  .quid-react-components__src-components-Typeahead-__Typeahead__caret {
    position: absolute;
    right: 0.93em;
    color: var(--primary);
    font-size: 1.11em;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    top: 0;
    pointer-events: none
}
  .quid-react-components__src-components-Typeahead-__Typeahead--disabled .quid-react-components__src-components-Typeahead-__Typeahead__input {
    background-color: var(--gray1);
    border-color: var(--gray2)
}
  .quid-react-components__src-components-Typeahead-__Typeahead--disabled .quid-react-components__src-components-Typeahead-__Typeahead__input, .quid-react-components__src-components-Typeahead-__Typeahead--disabled .quid-react-components__src-components-Typeahead-__Typeahead__caret {
    color: var(--gray3)
}
  .quid-react-components__src-components-Typeahead-__Typeahead__dropdown {
    position: absolute;
    top: 0;
    left: 0;
    margin-top: -1px;
    background-color: var(--primaryInverse);
    border-radius: 2px;
    color: var(--primary);
    overflow: hidden;
    border: 1px solid var(--primaryInverse);
    -webkit-box-shadow: 2px 2px 3px 0 hsla(0, 0%, 7%, .25);
            box-shadow: 2px 2px 3px 0 hsla(0, 0%, 7%, .25);
    max-height: 16.71em;
    overflow: auto
}
  .quid-react-components__src-components-Typeahead-__Typeahead__transitionGroup {
    display: block;
    width: 100%
}
  .quid-react-components__src-components-Typeahead-__Typeahead__dropdownAnimation {
    position: absolute;
    width: 100%;
    top: 0;
    z-index: 1
}
  .quid-react-components__src-components-Typeahead-__Typeahead__dropdownAnimation--entering {
      opacity: 0
}
  .quid-react-components__src-components-Typeahead-__Typeahead__dropdownAnimation--entered {
      opacity: 1;
      -webkit-transition: opacity .2s ease-in;
      -o-transition: opacity .2s ease-in;
      transition: opacity .2s ease-in
}
  .quid-react-components__src-components-Typeahead-__Typeahead__dropdownAnimation--exiting, .quid-react-components__src-components-Typeahead-__Typeahead__dropdownAnimation--exited {
      opacity: 0;
      -webkit-transition: opacity .1s ease-out;
      -o-transition: opacity .1s ease-out;
      transition: opacity .1s ease-out;
      pointer-events: none
}

.quid-react-components__src-components-Typeahead-Item-__Item {
  outline: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex
}

  .quid-react-components__src-components-Typeahead-Item-__Item__option {
    /* Reset */
    background-color: transparent;
    border: 0;
    padding: 0;
    margin: 0;
    font: inherit;
    color: inherit;
    text-align: inherit;

    /* Style */
    cursor: pointer;
    -webkit-transition: background-color .2s ease-in-out;
    -o-transition: background-color .2s ease-in-out;
    transition: background-color .2s ease-in-out
}

  .quid-react-components__src-components-Typeahead-Item-__Item__option:focus, .quid-react-components__src-components-Typeahead-Item-__Item__option--focus, .quid-react-components__src-components-Typeahead-Item-__Item__option:hover {
      background-color: var(--highlightBackground);
      outline: 0
}

  .quid-react-components__src-components-Typeahead-Item-__Item__option--active {
      color: var(--quidTeal);
      font-weight: bold
}

  .quid-react-components__src-components-Typeahead-Item-__Item__option, .quid-react-components__src-components-Typeahead-Item-__Item__heading {
    display: block;
    width: 100%;
    line-height: 1.8;
    padding-left: 0.65em;
    padding-right: 0.65em
}

  .quid-react-components__src-components-Typeahead-Item-__Item__divider {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    border: 0;
    border-top: 1px solid var(--highlightBackground);
    margin-left: 0.65em;
    margin-right: 0.65em
}

  .quid-react-components__src-components-Typeahead-Item-__Item__heading {
    color: var(--secondary)
}

  .quid-react-components__src-components-Typeahead-Item-__Item__button {
    margin-left: auto;
    margin-right: 0.65em
}
